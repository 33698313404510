import { userLoggedIn } from "../cookies.js";
import { getBasketFromCookie, replaceButtonWithCounter } from "./basketFunctions.js";

export function checkAllCardsForBasket(container) {
    const addInBasketBtns = container ? container.querySelectorAll('.__add_in_basket') : document.querySelectorAll('.__add_in_basket');
    if(addInBasketBtns.length > 0 && !userLoggedIn()) {
        addInBasketBtns.forEach(btn=>{
            const id = btn.dataset.id;
            const basket = getBasketFromCookie();
            if(id && basket.length > 0) {
                basket.forEach(elem=>{
                    if(elem.product == id) replaceButtonWithCounter(btn, elem.quantity);
                });
            };
        });
    };
}