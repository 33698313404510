import { genderChoice } from "./gender-choice.js"
import { familyStatus } from "./family-status.js"
import { logout } from "./logout.js"
import { addressChange } from "./addressChange.js"

function init() {
    genderChoice()
    familyStatus()
    logout()
    addressChange()
}

export {
    init
}