import { userLoggedIn } from "../../cookies.js";
import { checkAllCardsForBasket } from "../../basket/checkAllCardsForBasket.js";
import { getData, sendData } from "../../utils.js";
import { getWishlistFromCookie } from "../../wishlist/wishlistFunctions.js";
import { addCard } from "./addCard.js";

export async function wishlistFilters() {
    const filtersList = document.querySelector('.wishlist-section__filters-list');
    if(filtersList && !userLoggedIn()) {

        const wishlist = getWishlistFromCookie();

        sendData(wishlist, '/api/wishlist/get-filter-category/')
        .then(res=>{
            for(const item of res) {
                try {
                    addFilter(item, filtersList)
                } catch (error) {
                    console.log(error, 'error in showCard');
                }
            }
        })
        const filterAll = document.querySelector('#wishlist-filter-all');
        filterAll.addEventListener('click',()=>{
            const infoBlock = document.querySelector('.wishlist-section__info-block');
            infoBlock.innerHTML = '';
            for(const elem of wishlist) {
                const id = parseInt(elem.product);
                getData(`/api/products/${id}/`)
                .then(res=>{
                    addCard(res, infoBlock);
                });
            }
            checkAllCardsForBasket(infoBlock);
        });
    };
}
function addFilter(data, container) {
    const wishlist = getWishlistFromCookie();

    const label = document.createElement('label');
    label.classList.add('link','link_size_basic','link_primary');
    label.for = `wishlist-filter-${data.id}`
    label.innerHTML = `
        ${data.name}
        <span class="link__line"></span>
        <input type="radio"
                id="wishlist-filter-${data.id}"
                class="__filter_wishlist"
                name="wishlist-filters"
                data-query="${data.name}"
                data-value="${data.id}"
        >
    `;
    const wishlistFilter = label.querySelector('.__filter_wishlist');
    wishlistFilter.addEventListener('click',()=>{
        const id = wishlistFilter.dataset.value;
        sendData(wishlist, `/api/wishlist/filter-products/?category=${id}`)
        .then(res=>{
            const infoBlock = document.querySelector('.wishlist-section__info-block');
            infoBlock.innerHTML = '';
            for(const item of res) {
                addCard(item, infoBlock);
            }
        });
    });
    container.appendChild(label);

    checkAllCardsForBasket(container);
}