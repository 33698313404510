import { checkForEmptyUnreg } from './checkForEmptyUnreg.js';
import { wishlistFilters } from './wishlistFilters.js';
import { showCards } from './showCards.js';
import { deleteAllWishlist } from './deleteAllWishlist.js';

function init() {
    checkForEmptyUnreg();
    wishlistFilters();
    showCards();
    deleteAllWishlist();
}

export {
    init   
}