import { userLoggedIn } from "./cookies.js";
import { counterFunc } from "./pages/basket/cardsFunctions.js";

export function counter() {
    const counters = document.querySelectorAll('.counter');
    if(counters.length > 0 && userLoggedIn()) {
        counters.forEach(counter=>{
            const id = counter.dataset.id;
            counterFunc(counter, id);
        });
    };
}