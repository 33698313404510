import { removeFromBasket } from "../../basket/basketFunctions.js";
import { checkAllSelected, deleteCard } from "./cardsFunctions.js";

export function cardsDeletions() {
    const currentPage = window.location.pathname;
    const container = document.querySelector('.basket-section__items-list');
    if(currentPage.includes('/basket') && container) {

        const cards = container.querySelectorAll('.basket-card');
        cards.forEach(card=>{
            const selector = card.querySelector('.__card_selector');
            selector.addEventListener('click',()=>{
                checkAllSelected(container);
            });
            deleteCard(card, container);
        });
        
        const selectAllBtn = document.querySelector('#select__all');
        selectAllBtn.addEventListener('click',()=>{
            const selectors = container.querySelectorAll('.__card_selector');
            if(selectAllBtn.checked) {
                selectors.forEach(selector=>{
                    selector.checked = true;
                });
            } else {
                selectors.forEach(selector=>{
                    selector.checked = false;
                });
            };
        });

        const deleteAllSelected = document.querySelector('#__delete_all_selected');
        deleteAllSelected.addEventListener('click',()=>{
            const cards = container.querySelectorAll('.basket-card');

            let cardsToDelete = [];
            cards.forEach(card=>{
                const selector = card.querySelector('.__card_selector');
                if(selector.checked) cardsToDelete.push(card.dataset.id);
            });

            cardsToDelete.forEach(deleted=>{
                removeFromBasket(deleted);
            });

            setTimeout(() => {
                window.location.reload();
            }, 100);
        });
    };
}

