import { gsap } from "gsap/all";

import '../styles/main.scss';

// Scroll init
import { scroll } from "./libraries/scroll.js";
import { scrollTrigger } from "./animations/scroll-trigger.js";
import { parallax } from "./animations/parallax.js";

// Base funcs
import { accordion } from "./modules/base/accordion.js";
import { anchor } from "./modules/base/anchor.js";
import { modals } from "./modules/base/modals.js";
import { select } from "./modules/base/select.js";
import { inputs } from "./modules/inputs.js";
import { slider } from "./modules/slider.js";

// All menus
import { header } from "./modules/header/header.js";
import { headerSwap } from "./modules/header/header-swap.js";
import { headerMenus } from "./modules/header/header-menus.js";
import { burgerMenu } from "./modules/header/burger-menu.js";
import { searchMenu } from "./modules/header/search-menu.js";
import { globalMenu } from "./modules/header/global-menu.js";
import { filterMenuMobile } from "./modules/filterMenuMobile.js";

// All pages
import * as basket from './modules/basket/index.js';
import * as wishlist from './modules/wishlist/index.js';
import * as account from './modules/account/index.js';
import * as order from './modules/order/index.js';
import * as pages from './modules/pages/index.js';

import { validation } from "./modules/validation.js";
import { filters } from "./modules/filters.js";
import { pagination } from "./modules/pagination.js";
import { endlessPagination } from "./modules/endless-pagination.js";

import { counter } from "./modules/counter.js";
import { calendar } from "./modules/calendar.js";
import { productCard } from "./modules/product-card.js";

import { videoControls } from "./modules/video-control.js";
import { link_block } from "./modules/link-block.js";

import { cityHelper } from "./modules/city-helper.js";
import { dispatchInputs } from "./modules/dispatch-inputs.js";
import { prevLocation } from "./modules/prevLocation.js";
import { search } from "./modules/search.js";

import { countHeader } from "./modules/count-header.js";


scroll()
scrollTrigger()
parallax()

accordion()
anchor()
modals()
select()
inputs()
slider()


header()
headerSwap()
headerMenus()
burgerMenu()
searchMenu()
globalMenu()
filterMenuMobile()

basket.init()
wishlist.init()
account.init()
order.init()


validation()
filters()
pagination()
endlessPagination()

counter()
calendar()
productCard()

videoControls()
link_block()

cityHelper()
dispatchInputs()
prevLocation()
search()

countHeader()