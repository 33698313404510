export function addAccordion(data, fragment) {
    const accordion = document.createElement('div');
    const hr = document.createElement('hr');
    accordion.classList.add('accordion-item');
    accordion.innerHTML = `
        <div class="accordion-item__header">
            <div class="accordion-item__header__title">
                ${data.question}
            </div>
            <button class="icon-button icon-button_size_basic icon-button_primary">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_63_4996)">
                    <path d="M12 13.6717L16.95 8.72168L18.364 10.1357L12 16.4997L5.63599 10.1357L7.04999 8.72168L12 13.6717Z" fill="#1D1D1C"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_63_4996">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
        <div class="accordion-item__body">
            <div class="accordion-item__content">
                ${data.answer}
            </div>
        </div>
    `;

    const body = accordion.querySelector('.accordion-item__body');
    accordion.addEventListener('click',()=>{
        if(accordion.classList.contains('show')) {
            accordion.classList.remove('show');
            body.style.maxHeight = null;
        } else {
            accordion.classList.add('show');
            body.style.maxHeight = body.scrollHeight * 2 + "px";
        }
    });

    fragment.appendChild(accordion);
    fragment.appendChild(hr);
}