import { userLoggedIn } from "../../cookies.js";
import { getWishlistFromCookie } from "../../wishlist/wishlistFunctions.js";

export function checkForEmptyUnreg() {
    const currentPage = window.location.pathname;
    const wishlist = getWishlistFromCookie();

    const wishlistSection = document.querySelector('#wishlist');
    const wishlistEmptyBlock = document.querySelector('#wishlist-empty');

    if(currentPage.includes('/wishlist/') && wishlist && wishlist.length < 1 && !userLoggedIn()) {
        wishlistSection.parentNode.removeChild(wishlistSection);
        wishlistEmptyBlock.style.display = 'block';
    }
}