export function filterMenuMobile() {
    const openFilterBtns = document.querySelectorAll('.__open_filter-menu');
    const closeFilterBtns = document.querySelectorAll('.__close_filter-menu');
    const filterMenu = document.querySelector('.filter-menu');
    const inset = document.querySelector('.__inset');
    if(openFilterBtns.length > 0 && filterMenu) {
        openFilterBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                if(filterMenu.classList.contains('active')) {
                    filterMenu.classList.remove('active');
                    inset.classList.remove('show');
                } else {
                    filterMenu.classList.add('active');
                    inset.classList.add('show');
                };
            });
        });
        closeFilterBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                filterMenu.classList.remove('active');
                inset.classList.remove('show');
            });
        });
        inset.addEventListener('click',()=>{
            filterMenu.classList.remove('active');
        });
    };
}