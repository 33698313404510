export function genderChoice() {
    const maleInp = document.querySelector('.male-input');
    const femaleInp = document.querySelector('.female-input');
    if(maleInp && femaleInp) {
        let hideInp = document.querySelector('.gender-hide-input');

        if (femaleInp.checked) {
            maleInp.checked = false;
        } else if (maleInp.checked) {
            femaleInp.checked = false;
        }

        femaleInp.addEventListener('change', () => {
            maleInp.checked = false;
            hideInp.value = femaleInp.value;
        });

        maleInp.addEventListener('change', () => {
            femaleInp.checked = false;
            hideInp.value = maleInp.value;
        });
    }
}