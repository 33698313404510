export function category() {
    const section = document.querySelector('.category-section');

    if(section) {
        const decor = document.querySelector('.category-section__decoration');
        const categoryItem = section.querySelectorAll('.category-product');

        gsap.set(decor, { opacity: 0, y: '25%' });
        
        categoryItem.forEach(item => {
            gsap.set(item, { opacity: 0, y: '25%' });
        });

        const tl = gsap.timeline({ paused: true });

        tl.to(decor, { opacity: 1, y: 65, duration: 1 }, 0)
        .to(categoryItem, { opacity: 1, y: 0, duration: 1, stagger: .3 }, 0);

        ScrollTrigger.create({
            trigger: section,
            start: "top bottom",
            onEnter: () => tl.play(),
        });

        
    }
}