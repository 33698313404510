export function videoControls() {

    const openVideoBtn = document.querySelector('.__open__video_btn');
    const closeVideoBtn = document.querySelector('.__close__video_btn');
    if(openVideoBtn) {
        const videoModal = document.querySelector('.video-modal');
        const video = videoModal.querySelector('video');

        openVideoBtn.addEventListener('click',()=>{
            video.src = openVideoBtn.dataset.src;
            video.play();
            videoModal.style.display = 'flex';
            setTimeout(() => {
                videoModal.style.opacity = 1;
            }, 50);
        });

        closeVideoBtn.addEventListener('click',()=>{
            video.pause();
            videoModal.style.opacity = 0;
            setTimeout(() => {
                videoModal.style.display = null;
            }, 300);
        });
    }
}