export function calendar() {
    new AirDatepicker('#calendar', {
        dateFormat: 'dd.MM.yyyy',
        minDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
    });

    const birthdayInp = document.querySelector('input[name="birthday-hide"]');

    new AirDatepicker('#birthday', {
        dateFormat: 'dd.MM.yyyy',
        maxDate: new Date(),
        dateDelimiter: '-',
        autoClose: true,
        onSelect(date) {
            birthdayInp.value = date.formattedDate;
        }
    });
    new AirDatepicker('#order_date',{
        dateFormat: 'dd.MM.yyyy',
        maxDate: new Date(),
        dateDelimiter: '-',
        autoClose: true,
    })
}