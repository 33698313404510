import { checkAllCardsForBasket } from "../../basket/checkAllCardsForBasket.js";
import { userLoggedIn } from "../../cookies.js";
import { getData } from "../../utils.js";
import { getWishlistFromCookie } from "../../wishlist/wishlistFunctions.js";
import { addCard } from "./addCard.js";

export async function showCards() {
    const container = document.querySelector('.wishlist-section__info-block');
    const currentUrl = window.location.pathname;
    if(currentUrl.includes('/wishlist/') && container && !userLoggedIn()) {
        const wishlist = getWishlistFromCookie();
        if(wishlist && wishlist.length > 0) {
            for (const elem of wishlist) {
                try {
                    const id = parseInt(elem.product);
                    const data = await getData(`/api/products/${id}/`);
                    await addCard(data, container);
                } catch (error) {
                    console.log(error, 'error in showCard');
                }
            }
            checkAllCardsForBasket(container);
        };
    }
}