export async function map() {
    const mapMain = document.getElementById('map');
    if(mapMain) {
        await ymaps3.ready;

        const {YMap, YMapDefaultSchemeLayer, YMapMarker, YMapDefaultFeaturesLayer} = ymaps3;
        const coordinates = mapMain.dataset.coords;
        const splitted = coordinates.split(',');

        const center = [Number(splitted[1]), Number(splitted[0])];

        const map = new YMap(
            document.getElementById('map'),
            {
                location: {
                    center: center,
                    zoom: 16
                }
            }
        );


        const layer = new YMapDefaultSchemeLayer({
            customization: [
                {
                    tags: {all: ['water']},
                    elements: 'geometry',
                    stylers: [{color: '#1F1014'}]
                },
                {
                    tags: {all: ['water']},
                    elements: 'label',
                    stylers: [{color: '#E1416B'}]
                },
                {
                    tags: {any: ['entrance']},
                    elements: 'geometry.fill',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {any: ['poi', 'transit_location', 'transit_stop', 'transit_entrance', 'fuel_station', 'hotel', 'food_and_drink', 'shopping', 'commercial_services', 'medical', 'cemetery', 'major_landmark', 'park', 'beach', 'sports_ground']},
                    elements: 'label',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['poi']},
                    elements: 'label.text.outline',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['major_landmark']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'label.text.outline',
                    stylers: [{color: '#685258'}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'label',
                    stylers: [{color: '#685258'}]
                },
                {
                    tags: {all: ['road_1']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['road_2']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['road_limited']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {any: ['road_unclassified']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['road_minor']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['path']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['landscape']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['landcover']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['vegetation']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['structure']},
                    elements: 'geometry',
                    stylers: [{color: '#1F1014'}]
                },
                {
                    tags: {all: ['urban_area']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['residential']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {any: ['urban_area', 'residential', 'industrial']},
                    elements: 'label',
                    stylers: [{color: "#685258"}]
                },
                {
                    tags: {any: ['urban_area', 'residential', 'industrial']},
                    elements: 'label.text.outline',
                    stylers: [{color: "#685258"}]
                },
                {
                    tags: {any: ['industrial']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {any: ['sports_ground']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['district']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['building']},
                    elements: 'geometry',
                    stylers: [{color: '#1F1014'}]
                },
                {
                    tags: {all: ['fence']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['construction_site']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['park']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['medical']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['cemetery']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['hotel']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {all: ['fuel_station']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['beach']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['courtyard']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['yard']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['common_area']},
                    elements: 'geometry',
                    stylers: [{color: '#231F20'}]
                },
                {
                    tags: {all: ['address']},
                    elements: 'label',
                    stylers: [{color: '#685258'}]
                },
                {
                    tags: {all: ['locality']},
                    elements: 'label',
                    stylers: [{color: '#E1416B'}]
                },
                {
                    tags: {all: ['district']},
                    elements: 'label',
                    stylers: [{color: '#E1416B'}]
                },
                {
                    tags: {all: ['district']},
                    elements: 'label.text',
                    stylers: [{color: '#E1416B'}]
                },
                {
                    tags: {all: ['land']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                },
                {
                    tags: {any: ['country', 'region', 'locality', 'district', 'address']},
                    elements: 'geometry',
                    stylers: [{color: '#070505'}]
                }
            ]
        });

        map.addChild(layer);

        const featuresLayer = new YMapDefaultFeaturesLayer();
        map.addChild(featuresLayer);

        // const markerElement = document.createElement('div');
        // markerElement.className = 'custom-marker';
        // markerElement.style.width = '64px';
        // markerElement.style.height = '64px';
        // markerElement.style.backgroundImage = 'url(../static/assets/images/icons/map-pin-icon.svg)';
        // markerElement.style.backgroundSize = 'cover';
        // markerElement.style.backgroundPosition = 'center';
        // markerElement.style.borderRadius = '50%';
        // markerElement.style.position = 'absolute';
        // markerElement.style.left = '50%';
        // markerElement.style.top = '50%';
        // markerElement.style.transform = 'translate(-50%, -50%)';

        // markerElement.style.zIndex = '1000';


        // const marker = new YMapMarker(
        //     {
        //         coordinates: center,
        //     },
        //     markerElement
        // );

        // map.addChild(marker);
    }
}