// import { moveToOrder } from "./moveToOrder.js"
import { addressSelect } from "./addressSelect.js"


function init() {
    // moveToOrder()
    addressSelect()
}

export {
    init   
}