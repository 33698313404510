export function pagination() {
    document.addEventListener('DOMContentLoaded', function() {
        var loadMoreButton = document.querySelector('.load-more-btn');
    
        if(loadMoreButton) {
            loadMoreButton.addEventListener('click', function() {
                var button = this;
                var nextPage = button.getAttribute('data-next-page');
                var xhr = new XMLHttpRequest();
                
                xhr.open('GET', window.location.pathname + '?page=' + nextPage, true);
    
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            button.textContent = 'Загрузить больше';
                            var parser = new DOMParser();
                            var doc = parser.parseFromString(xhr.responseText, 'text/html');
        
                            var newArticles = doc.querySelector('#article-list').innerHTML;
                            document.querySelector('#article-list').insertAdjacentHTML('beforeend', newArticles);
        
                            var newButton = doc.querySelector('.load-more-btn');
                            if (newButton) {
                                button.setAttribute('data-next-page', newButton.getAttribute('data-next-page'));
                            } else {
                                button.remove();
                            }
                        } else {
                            button.textContent = 'Загрузить больше';
                        }
                    } else {
                        button.textContent = 'Загрузка...';
                    }
                };
                xhr.send();
            });
        };
    });
}

// Degenerated by GPT