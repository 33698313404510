import { userLoggedIn } from "../cookies.js";
import { counterFunc } from "../pages/basket/cardsFunctions.js";
import { getData } from "../utils.js";
import { btnWishlistFunc, getWishlistFromCookie } from "../wishlist/wishlistFunctions.js";
import { getBasketFromCookie, removeFromBasket } from "./basketFunctions.js";

export async function showBasketInMenu() {
    const menu = document.querySelector('.__basket_in_menu');
    if(!userLoggedIn() && menu) {
        const bakset = getBasketFromCookie();
        if(bakset.length > 0) {
            const fragment = document.createDocumentFragment();
            for (const item of bakset) {
                try {
                    menu.innerHTML = '';
                    const data = await getData(`/api/products/${item.product}/`);
                    await addBasketCard(data, fragment, item);
                } catch (error) {
                    console.log(error, 'error in basket menu');
                }
            }
            menu.appendChild(fragment);
        }
    }
}
async function addBasketCard(data, fragment, item) {
    const card = document.createElement('div');
    const hr = document.createElement('hr');
    card.classList.add('product-card', 'product-card_wishlist', 'product-card_wishlist_cart');

    let chars = '';
    if(data.characteristics.length > 0) {
        for (const [i, char] of data.characteristics.entries()) {
            if(i !== 0) {
                chars += `
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.970186 3.02982C0.429054 2.99803 0 2.54912 0 2C0 1.45088 0.429054 1.00197 0.970186 0.970179C1.00198 0.429034 1.45089 0 2 0C2.54911 0 2.99802 0.429034 3.02981 0.970179C3.57095 1.00197 4 1.45088 4 2C4 2.54912 3.57095 2.99803 3.02981 3.02982C2.99802 3.57097 2.54911 4 2 4C1.45089 4 1.00198 3.57097 0.970186 3.02982Z" fill="#1D1D1C"/>
                </svg>
                ${char.value}
                `;
            } else {
                chars += char.value;
            }
        }
    }

    let price = `${data.price} Р`;
    if(data.discount && data.discount !== 0) {
        price = `
            ${data.price} Р
            <span>${data.base_price} Р</span>
        `
    }

    let photos = '';
    let tabs = '';
    if(data.photos.length > 0) {
        for (const [i, photo] of data.photos.entries()) {
            const img = `<img src="${photo.photo}" alt="product-img" data-image="${i+1}">`;
            const tab = `<div class="product-card__tabs__tab" data-image-tab="${i+1}"></div>`;
            photos += img;
            tabs +=tab;
        }
    } else {
        const img = `<img src="/static/assets/images/stub.webp" alt="product-img">`;
        photos = img;
    }

    card.innerHTML = `
        <div class="product-card__img-block img-variant img-variant__1-1">
            ${photos}

            <a href="/product/${data.slug}/" class="product-card__tabs">
                ${tabs}
            </a>

            <div class="product-card__favourite-btn" data-id="${data.id}">
                <button class="icon-button icon-button_size_basic icon-button_subdued">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z" fill="#6D6B6B"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="product-card__info-block">
            <a href="/product/${data.slug}/" class="product-card__title-block">
                <div class="product-card__price-block">
                    ${price}
                </div>
                <div class="product-card__title-block__product-name">
                    ${data.name}
                </div>
                <div class="specs">
                    ${chars}
                </div>
            </a>
            <div class="product-card__counter">
                <div class="counter" data-id="${data.id}">
                    <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="minus">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 8.25H14.25V9.75H3.75V8.25Z" fill="#1D1D1C"/>
                        </svg>
                    </button>
                    <input type="text" readonly value="${item.quantity}"></input>
                    <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="plus">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" fill="#1D1D1C"/>
                        </svg>
                    </button>
                </div>
                <button class="icon-button icon-button_size_small icon-button_subdued __delete_product">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 4V6H15V4H9Z" fill="#B5B0B1"/>
                    </svg>
                </button>
            </div>
        </div>
    `;

    const wishlistBtn = card.querySelector('.product-card__favourite-btn');
    wishlistBtn.addEventListener('click',()=>{
        btnWishlistFunc(wishlistBtn);
    });

    const wishlist = getWishlistFromCookie();
    wishlist.forEach(elem=>{
        if(elem.product == data.id) {
            wishlistBtn.querySelector('.icon-button').classList.add('icon-button_active');
            wishlistBtn.querySelector('.icon-button').innerHTML = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z" fill="black"/>
                </svg>
            `;
        };
    });

    const counter = card.querySelector('.counter');
    counterFunc(counter, data.id);

    const deleteCardBtn = card.querySelector('.__delete_product');
    deleteCardBtn.addEventListener('click',()=>{
        removeFromBasket(data.id);
        card.parentNode.removeChild(card);
        hr.parentNode.removeChild(hr);
    });

    fragment.appendChild(card);
    fragment.appendChild(hr);
}