let ticking = false;

export function parallax() {
    document.addEventListener('scroll', () => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                parallaxFunctionary();
                ticking = false;
            });
            ticking = true;
        }
    }, { passive: true });
};

function parallaxFunctionary() {
    const parallaxSections = document.querySelectorAll('.__parallax_section');
    if (!parallaxSections.length) return;

    parallaxSections.forEach(section => {
        const sectionRect = section.getBoundingClientRect();
        if (sectionRect.top < window.innerHeight && sectionRect.bottom > 0) {
            const parallaxElems = section.querySelectorAll('.__parallax_section__elem');
            parallaxElems.forEach(elem => {
                const depth = elem.dataset.depth;
                const translateY = sectionRect.top * depth;
                elem.style.transform = `translate3d(0px, ${translateY}px, 0px)`;
            });
        }
    });
}