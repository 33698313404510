export function jewelry() {
    const section = document.querySelector('.jewelry-global-section');

    if(section) {
        const clouds = section.querySelectorAll('.jewelry-banner-section__cloud');
        const decorRight = section.querySelector('.jewelry-banner-section__decoration.right');
        const decorLeft = section.querySelector('.jewelry-banner-section__decoration.left');

        clouds.forEach(cloud => {
            gsap.set(cloud, { opacity: 0, y: '25%' });
        });
        gsap.set(decorRight, { x: '100%' });
        gsap.set(decorLeft, { x: '-100%' });

        const tl = gsap.timeline({ paused: true });

        tl.to(clouds, { opacity: 1, y: 0, duration: 1, stagger: .2 }, 0)
        .to(decorRight, { x: 0, duration: 1 }, .5)
        .to(decorLeft, { x: 0, duration: 1 }, .5),

        ScrollTrigger.create({
            trigger: section,
            start: "top bottom",
            onEnter: () => tl.play(),
        });

        const jewelrySlider = new Swiper('.jewelry-banner-section_slider',{
            slidesPerView: 1,
            invert: true,
            effect: 'creative',
            creativeEffect: {
                prev: {
                  translate: ['-25%', 0, 0],
                  opacity: 0,
                },
                next: {
                  translate: ['-25%', 0, 0],
                  opacity: 0,
                }
            },
            speed: 600,
            
            navigation: {
                nextEl: ".jewelry-banner-section_slider__next-btn",  
                prevEl: ".jewelry-banner-section_slider__prev-btn",
            },
            mousewheel: {
                forceToAxis: true
            },
            on: {
                slideChange: function () {
                    const allSlides = this.slides;
                    allSlides.forEach(slide=>{
                        setTimeout(() => {
                            const slideClouds = slide.querySelectorAll('.jewelry-banner-section__cloud');
                            if(slide.classList.contains('swiper-slide-active')) {
                                slideClouds.forEach((cloud, index)=>{
                                    setTimeout(() => {
                                        gsap.to(cloud, { opacity: 1, y: 0, duration: 1 });
                                    }, 200 * index + 1);
                                })
                            } else {
                                slideClouds.forEach(cloud=>{
                                    gsap.to(cloud, { opacity: 0, y: '25%', duration: 0.2 });
                                });
                            };
                        }, 100);
                    });
                }
            }
        });
        



    }
}