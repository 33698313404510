import { addInBasket } from "./addInBasket.js";
import { checkAllCardsForBasket } from "./checkAllCardsForBasket.js";

function init() {
    addInBasket();
    checkAllCardsForBasket();
}

export {
    init
}