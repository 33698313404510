import { btnBasketFunc, getBasketFromCookie } from "../basket/basketFunctions.js";
import { userLoggedIn } from "../cookies.js";
import { getData } from "../utils.js";
import { btnWishlistFunc, getWishlistFromCookie } from "./wishlistFunctions.js";
import { counterFunc } from '../pages/basket/cardsFunctions.js';

export async function showWishlistInMenu() {
    const menu = document.querySelector('.__wishlist_in_menu');
    if(!userLoggedIn() && menu) {
        const wishlist = getWishlistFromCookie();
        if(wishlist.length > 0) {
            const fragment = document.createDocumentFragment();
            for (const item of wishlist) {
                try{
                    menu.innerHTML = '';
                    const data = await getData(`/api/products/${item.product}/`);
                    await addWishlistCard(data, fragment);
                } catch (error) {
                    console.log(error, 'error in wishlist menu');
                }
            }
            menu.appendChild(fragment);
        };
    }
}

async function addWishlistCard(data, fragment) {
    const card = document.createElement('div');
    card.classList.add('product-card', 'product-card_wishlist');

    let chars = '';
    if(data.characteristics.length > 0) {
        for (const [i, char] of data.characteristics.entries()) {
            if(i !== 0) {
                chars += `
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.970186 3.02982C0.429054 2.99803 0 2.54912 0 2C0 1.45088 0.429054 1.00197 0.970186 0.970179C1.00198 0.429034 1.45089 0 2 0C2.54911 0 2.99802 0.429034 3.02981 0.970179C3.57095 1.00197 4 1.45088 4 2C4 2.54912 3.57095 2.99803 3.02981 3.02982C2.99802 3.57097 2.54911 4 2 4C1.45089 4 1.00198 3.57097 0.970186 3.02982Z" fill="#1D1D1C"/>
                </svg>
                ${char.value}
                `
            } else {
                chars += char.value
            }
        }
    }

    let price = `${data.price} Р`;
    if(data.discount && data.discount !== 0) {
        price = `
            ${data.price} Р
            <span>${data.base_price} Р</span>
        `
    }

    let photos = '';
    let tabs = '';
    if(data.photos.length > 0) {
        for (const [i, photo] of data.photos.entries()) {
            const img = `<img src="${photo.photo}" alt="product-img" data-image="${i+1}">`;
            const tab = `<div class="product-card__tabs__tab" data-image-tab="${i+1}"></div>`;
            photos += img;
            tabs +=tab;
        }
    } else {
        const img = `<img src="/static/assets/images/stub.webp" alt="product-img">`;
        photos = img;
    }
    

    card.innerHTML = `
        <div class="product-card__img-block img-variant img-variant__1-1">
            ${photos}

            <a href="/product/${data.slug}/" class="product-card__tabs">
                ${tabs}
            </a>

            <div class="product-card__favourite-btn" data-id="${data.id}">
                <button class="icon-button icon-button_size_basic icon-button_subdued icon-button_active">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z" fill="black"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="product-card__info-block">
            <a href="/product/${data.slug}/" class="product-card__title-block">
                <div class="product-card__title-block__cost-block">
                    ${price}
                </div>
                <div class="product-card__title-block__product-name">
                    ${data.name}
                </div>
                <div class="specs">
                    ${chars}
                </div>
            </a>
            ${basketBtn(data)}
        </div>
    `;

    const tabsCard = card.querySelectorAll('.product-card__tabs__tab');
    const imagesCard = card.querySelectorAll('.product-card__img-block img');

    tabsCard.forEach(tab => {
        tab.addEventListener('mouseenter', function() {
            imagesCard.forEach(img => {
                if(tab.dataset.imageTab === img.dataset.image) {
                    img.style.opacity = '1';
                } else {
                    img.style.opacity = '0';
                }
            });
        });
    });

    const wishlistBtn = card.querySelector('.product-card__favourite-btn');
    wishlistBtn.addEventListener('click',()=>{
        btnWishlistFunc(wishlistBtn);
    });

    const btnBasket = card.querySelector('.__add_in_basket');
    if(btnBasket) {
        btnBasket.addEventListener('click',()=>{
            btnBasketFunc(btnBasket);
        });
    }
    const counter = card.querySelector('.counter');
    if(counter) {
        counterFunc(counter, data.id);
    }

    const hr = document.createElement('hr');

    fragment.appendChild(card);
    fragment.appendChild(hr);
}

function basketBtn(data) {
    let btn;

    const basket = getBasketFromCookie();
    if(basket && basket.length > 0) {
        let checker = true;
        basket.forEach(elem=>{
            if(data.id == elem.product) {
                btn = `
                    <div class="counter">
                        <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="minus">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 8.25H14.25V9.75H3.75V8.25Z" fill="#1D1D1C"/>
                            </svg>
                        </button>
                        <input type="text" value="${elem.quantity}"></input>
                        <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="plus">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" fill="#1D1D1C"/>
                            </svg>
                        </button>
                    </div>
                `;
                checker = false;
            } else if(checker) {
                btn = `
                    <button class="button button_size_small button_accent __add_in_basket" data-id="${data.id}">
                        <span>В корзину</span>
                        <span>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_63_9893)">
                                <path d="M8.75 8.25V3.75H10.25V8.25H14.75V9.75H10.25V14.25H8.75V9.75H4.25V8.25H8.75Z" fill="#FCF5F7"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_63_9893">
                                <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </button>
                `;
            }
        });
    } else {
        btn = `
            <button class="button button_size_small button_accent __add_in_basket" data-id="${data.id}">
                <span>В корзину</span>
                <span>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_63_9893)">
                        <path d="M8.75 8.25V3.75H10.25V8.25H14.75V9.75H10.25V14.25H8.75V9.75H4.25V8.25H8.75Z" fill="#FCF5F7"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_63_9893">
                        <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </span>
            </button>
        `;
    }

    return btn;
}