export function zoomButtons() {
    const zoomBtns = document.querySelectorAll('.zoom-button');
    if(zoomBtns.length > 0) {
        const modalProductSwiper = new Swiper(".modalProductSwiper", {
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            slidesPerView: 1,
        });

        // const productSlider = document.querySelector('.modalProductSwiper').querySelector('.swiper-wrapper');
        zoomBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const number = btn.dataset.number;
                modalProductSwiper.slideTo(number - 1, .5);
            });
        });
    };
}