import { checkAllSelected, counterFunc, deleteCard } from "./cardsFunctions.js";

import { calcPrice } from "./dinamicPrices.js";

export function addCard(data, container, quantity) {
    const card = document.createElement('div');
    card.classList.add('basket-card');
    card.dataset.id = data.id;

    let priceWithDiscount = '';
    if(data.discount) {
        priceWithDiscount = `
            ${data.price} Р
            <span>${data.base_price} Р</span>
        `
    }else {
        priceWithDiscount = `
            ${data.base_price} Р 
        `
    }
    let characteristics = '';
    if(data.characteristics.length > 0) {
        data.characteristics.forEach(char=>{
            characteristics = characteristics + `
                <span>${char.value}</span>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.970186 3.02982C0.429054 2.99803 0 2.54912 0 2C0 1.45088 0.429054 1.00197 0.970186 0.970179C1.00198 0.429034 1.45089 0 2 0C2.54911 0 2.99802 0.429034 3.02981 0.970179C3.57095 1.00197 4 1.45088 4 2C4 2.54912 3.57095 2.99803 3.02981 3.02982C2.99802 3.57097 2.54911 4 2 4C1.45089 4 1.00198 3.57097 0.970186 3.02982Z" fill="#B5B0B1"/>
                </svg>
            `
        });
    }
    const photo = data.main_photo ? data.main_photo : '/static/assets/images/stub.webp';

    card.innerHTML = `
    <div class="basket-card__image-block">
        <label for="select-item-${data.id}" class="custom-checkbox-label">
            <input type="checkbox" id="select-item-${data.id}" class="__card_selector">
        </label>
        <img src="${photo}" alt="item-image">
    </div>
    <div class="basket-card__info-block">
        <a href="/product/${data.slug}/" class="basket-card__price-block">
            <div class="basket-card__price-block__prices">
                ${priceWithDiscount}
            </div>
            <div class="basket-card__price-block__title">
                ${data.name}
            </div>
            <div class="basket-card__price-block__desc">
                ${characteristics}
            </div>
        </a>
        <div class="basket-card__info-block__info">
            <div class="counter">
                <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="minus">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.75 8.25H14.25V9.75H3.75V8.25Z" fill="#1D1D1C"/>
                    </svg>
                </button>
                <input type="text" value="${quantity}"></input>
                <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="plus">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" fill="#1D1D1C"/>
                    </svg>
                </button>
            </div>
            <button class="icon-button icon-button_size_small icon-button_subdued __delete_product">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 4V6H15V4H9Z" fill="#B5B0B1"/>
                </svg>
            </button>
        </div>
    </div>
    <button class="icon-button icon-button_size_basic icon-button_subdued __delete_product">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 4V6H15V4H9Z" fill="#B5B0B1"/>
        </svg>
    </button>
    `;

    const selector = card.querySelector('.__card_selector');
    selector.addEventListener('click',()=>{
        checkAllSelected(container);
    });
    selector.checked = true;

    const counter = card.querySelector('.counter');
    counterFunc(counter, card.dataset.id);

    deleteCard(card);


    if(container) {
        container.appendChild(card);
    }

    calcPrice();
}