export function dispatchInputs() {
    const form = document.querySelector('#user-info-update-form');
    if (form) {
        const inputs = document.querySelectorAll('.__dispatch-input');
        const inputGlobal = form.querySelector('#first_name');
  
        const observer = new MutationObserver(() => {
            const event = new Event('input', { bubbles: true });
            inputGlobal.dispatchEvent(event);
        });
  
        inputs.forEach((input) => {
            observer.observe(input, {
                attributeFilter: ['value'],
                attributeOldValue: true,
            });
        });
    }
}