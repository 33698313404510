import { userLoggedIn } from "./cookies.js";
import { getBasketFromCookie } from "./basket/basketFunctions.js";
import { getWishlistFromCookie } from "./wishlist/wishlistFunctions.js";

export function countHeader() {
    updateBadgeCount();
}

export function updateBadgeCount() {
    const badges = document.querySelectorAll('.__badge-icon');

    if(badges.length === 0) return;

    badges.forEach(badge=>{
        const category = badge.dataset.headerHover;
        
        if(category && !userLoggedIn()) {
            let items;
            let totalQuantity = '';
            if(category == 'cart') {
                items = getBasketFromCookie();
                totalQuantity = items.reduce((acc, item) => acc + item.quantity, '');
            } else if (category == 'wishlist') {
                items = getWishlistFromCookie();
                items.forEach((item,index)=>{
                    totalQuantity = index + 1;
                });
            }
            badge.dataset.count = totalQuantity;

        };
    });

}
