import { setCookie, userLoggedIn } from '../cookies.js';
import { updateBadgeCount } from '../count-header.js';
import { sendData } from '../utils.js';

export const getCookiesWishlist = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)wishlist\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export function getWishlistFromCookie() {
    const wishlistCookies = getCookiesWishlist();
    return wishlistCookies ? JSON.parse(wishlistCookies) : [];
}

export async function addToWishlist(productId) {
    if(userLoggedIn()) {
        const productObj = {};
        if(typeof productId === 'string' || productId instanceof String) {
            productObj.product = parseInt(productId);
        } else {
            productObj.product = productId;
        }
        sendData(productObj, '/api/wishlist/add/');
    } else {
        let wishlist = getWishlistFromCookie();
        let index = '';
        if(typeof productId === 'string' || productId instanceof String) {
            index = wishlist.findIndex(item => item.product === parseInt(productId)) 
        } else {
            index = wishlist.findIndex(item => item.product === productId) 
        }
        if (index !== -1) {
            wishlist.splice(index, 1);
        }
        if(typeof productId === 'string' || productId instanceof String) {
            wishlist.push({
                "product": parseInt(productId),
            });
        } else {
            wishlist.push({
                "product": productId,
            });
        }
        setCookie('wishlist', JSON.stringify(wishlist));
    }
    updateBadgeCount()
}

export async function removeFromWishlist(productId) {
    if(userLoggedIn()) {
        const productObj = {};
        if(typeof productId === 'string' || productId instanceof String) {
            productObj.product = parseInt(productId);
        } else {
            productObj.product = productId;
        }
        sendData(productObj, '/api/wishlist/remove/');
    } else {
        let wishlist = getWishlistFromCookie();
        let index = '';
        if(typeof productId === 'string' || productId instanceof String) {
            index = wishlist.findIndex(item => item.product === parseInt(productId)) 
        } else {
            index = wishlist.findIndex(item => item.product === productId) 
        }
        if (index !== -1) {
            wishlist.splice(index, 1);
        }
        setCookie('wishlist', JSON.stringify(wishlist));
    }
    updateBadgeCount()
};

export function btnWishlistFunc(btn) {
    let iconBtn = btn.classList.contains('icon-button') ? btn : btn.querySelector('.icon-button');
    const id = btn.dataset.id;

    if(iconBtn.classList.contains('icon-button_active')) {
        iconBtn.classList.remove('icon-button_active');
        iconBtn.innerHTML = `
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
                    fill="#6D6B6B"/>
            </svg>
        `;
        removeFromWishlist(id);
    } else {
        iconBtn.classList.add('icon-button_active');
        iconBtn.innerHTML = `
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z" fill="black"/>
            </svg>
        `;
        addToWishlist(id);
    };
}