import { addToBasket, removeFromBasket } from "../../basket/basketFunctions.js";
import { debounce } from "../../utils.js";
import { calcPrice } from "./dinamicPrices.js";

export function counterFunc(counter, id) {
    const btns = counter.querySelectorAll('.__counter_btn');
    const input = counter.querySelector('input');
    btns.forEach(btn=>{
        const char = btn.dataset.char;
        btn.addEventListener('click',()=>{
            if(char == 'minus' && parseInt(input.value) > 1) {
                input.value = parseInt(input.value) - 1;
            } else if(char == 'plus') {
                input.value = parseInt(input.value) + 1;
            };

            addToBasket(id, parseInt(input.value));
            calcPrice();
        });
        input.addEventListener('input',()=>{
            input.value = input.value.replace(/\D/g, '');
            debouncedAddToBasket(id, parseInt(input.value));
            debouncedCalcPrice();
        });
    });
};
const debouncedCalcPrice = debounce(calcPrice, 300);
const debouncedAddToBasket = debounce(addToBasket, 300);

export function checkAllSelected(container) {
    const selectAllBtn = document.querySelector('#select__all');
    const allSelectors = container.querySelectorAll('.__card_selector');

    let allSelected = true;
    allSelectors.forEach(selector=>{
        if(!selector.checked) allSelected = false;
    });
    allSelected ? selectAllBtn.checked = true : selectAllBtn.checked = false;

    calcPrice();
}

export function deleteCard(card) {
    const id = card.dataset.id;
    const deleteBtns = card.querySelectorAll('.__delete_product');
    deleteBtns.forEach(btn=>{
        btn.addEventListener('click',()=>{
            removeFromBasket(id);
            card.parentNode.removeChild(card);

            calcPrice();
        });
    });
}