import { debounce, getCurrentQuery } from "./utils.js";

export function filters() {
    const filterElems = document.querySelectorAll('.filter__elem');
    if(filterElems.length > 0) {
        filterElems.forEach(filter=>{
            const activeQueries = getCurrentQuery();
            const activeKey = filter.getAttribute('data-query');
            const activeValue = filter.getAttribute('data-value');

            activeQueries.forEach(query=>{
                if(query[`${activeKey}`] == activeValue) filter.checked = true;
            });
            filter.addEventListener('click',()=>{
                let filtersArray = [];
                filterElems.forEach(elem=>{
                    const clickedKey = elem.getAttribute('data-query');
                    const clickedValue = elem.getAttribute('data-value');
                    const filterObject = {};
                    if(elem.checked) {
                        filterObject.key = clickedKey;
                        filterObject.value = clickedValue;
                        filtersArray.push(filterObject);
                    };
                });
                debouncedFilterWithArray(filtersArray);
            });
        });
    };

    let mobileFiltersArray = [];

    const mobileFilters = document.querySelectorAll('.filter__elem_mobile');
    if(mobileFilters.length > 0) {
        mobileFilters.forEach(filter=>{
            const activeQueries = getCurrentQuery();
            const activeKey = filter.getAttribute('data-query');
            const activeValue = filter.getAttribute('data-value');

            activeQueries.forEach(query=>{
                if(query[`${activeKey}`] == activeValue) filter.checked = true;
            });
            filter.addEventListener('click',()=>{
                let filtersArray = [];
                mobileFilters.forEach(elem=>{
                    const clickedKey = elem.getAttribute('data-query');
                    const clickedValue = elem.getAttribute('data-value');
                    const filterObject = {};
                    if(elem.checked) {
                        filterObject.key = clickedKey;
                        filterObject.value = clickedValue;
                        filtersArray.push(filterObject);
                    };
                });
                mobileFiltersArray = filtersArray;
            });
        });
    }

    document.querySelectorAll('input[name="category"]').forEach(function(radio) {
        radio.addEventListener('change', function() {
            const selectedValue = this.value;   
            const queryParams = new URLSearchParams(window.location.search);
            
            if (selectedValue === '') {
                queryParams.delete('category');
            } else {
                queryParams.set('category', selectedValue);
            }
            window.location.search = queryParams.toString();
        });
    });

    const confirmBtns = document.querySelectorAll('.filter__button_confirm');
    if(confirmBtns.length > 0) {
        confirmBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                filterWithArray(mobileFiltersArray);
            });
        });
    }
    
    const clearBtns = document.querySelectorAll('.filter__button_clear');
    if(clearBtns.length > 0) {
        clearBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                filterWithArray(Array);
            });
        })
    }
}

const debouncedFilterWithArray = debounce(filterWithArray, 1000);

function filterWithArray(filtersArray) {
    const url = new URLSearchParams();
    if(Array.isArray(filtersArray) && filtersArray.length) {
        for(const elem of filtersArray) {
            try {
                url.append(elem.key, elem.value);
            } catch(error) {
                console.log(error, 'Error on filters');
            }
        }
    } else {
        window.location.search = url.toString();
    }
    window.location.search = url.toString();
}

