import { addCardProducts } from "./pages/catalog/addCards.js";
import { getCurrentQuery, getData, setNewQuery } from "./utils.js";

export function endlessPagination() {
    const paginationElem = document.querySelectorAll('.endless-pagination__elem');
  
    if (paginationElem.length > 0) {
      paginationElem.forEach(elem => {
        const paginationAttr = elem.getAttribute('data-attr');
        const limitAttr = elem.dataset.limit;
        let currentPage = 1;
  
        if (paginationAttr) {
            const reloadedPageHref = new URL(window.location.href);
            reloadedPageHref.searchParams.set('page', 1);
            history.replaceState({}, '', reloadedPageHref.search.toString());

            const filters = getCurrentQuery();
            let filtersAll = '';
            filters.forEach(filter=>{
                if(!filter['page']) {
                    const [key, value] = Object.entries(filter)[0];
                    filtersAll += `&${key}=${value}`
                }
            })

            let category = '';
            if(paginationAttr == 'products') {
                const getCategory = reloadedPageHref.pathname.split('/');
                category = `&category=${getCategory[2]}`;
            }

            getData(`/api/${paginationAttr}/?page=1${category}${filtersAll}`)
            .then(data => {
                let section = '';
    
                if (data.next == null) elem.parentElement.removeChild(elem);
    
                if (paginationAttr == 'products') {
                    section = document.querySelector('.catalog-section__catalog-block__cards');
                    addCardProducts(data.results, section);
                }
            });
  
            elem.addEventListener('click', () => {
                currentPage++;
                const offset = (currentPage - 1) * limitAttr;
                const href = new URL(window.location.href);
                href.searchParams.set('page', currentPage.toString());
                history.replaceState({}, '', href.search.toString());
    
                getData(`/api/${paginationAttr}/?page=${currentPage}${category}${filtersAll}`)
                .then(data => {
                    let section = '';
    
                    if (data.next !== null) {
                    setNewPage();
                    } else {
                    elem.parentElement.removeChild(elem);
                    }
    
                    if (paginationAttr == 'products') {
                        section = document.querySelector('.catalog-section__catalog-block__cards');
                        addCardProducts(data.results, section);
                    }
                });
            });
        }
      });
    }
  }

export function setNewPage() {
    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');
    if(pageValue !== null) {
        pageValue = parseInt(pageValue) + 1;
        href.searchParams.set('page', pageValue.toString());
    } else {
        href.searchParams.set('page', 2);
    }
    history.replaceState({}, '', href.search.toString());
}