export function searchMenu() {
    const searchBtn = document.querySelector('.search-open');
    const headerSection = document.querySelector('.header-section');
    const headerIcon = document.querySelector('.header-section__icon-block');
    const headerNav = document.querySelectorAll('.header-section__nav-block');

    const searchClose = document.querySelector('.search-close');
    const searchMenu = document.querySelector('.header-section__search-menu');

    const searchFormMobile = document.querySelector('.header-section__search-menu__search-form_mobile');
    const searchBlock = document.querySelector('.header-section__btn-search-block');


    if(!searchBtn) return
    searchBtn.addEventListener('click', function() {
        headerNav.forEach(nav => {
            searchMenu.style.top = 0;
            headerIcon.style.opacity = 0;
            nav.style.opacity = 0;
            nav.classList.add('active');
            headerSection.style.setProperty('--decor-op', 0);
    
            document.documentElement.style.overflow = 'hidden';
            setTimeout(() => {
                headerIcon.style.display = 'none';
                nav.style.display = 'none';
                headerSection.style.setProperty('--decor-d', 'none');
    
                searchBlock.style.display = 'flex';
            }, 300);
            if(window.innerWidth <= 768) {
                searchFormMobile.style.display = 'flex';
            } else {
                searchFormMobile.style.display = null;
            }
        });
    });

    searchClose.addEventListener('click', function() {
        headerNav.forEach(nav => {
            headerIcon.style.display = null;
            nav.style.display = null;
            headerSection.style.setProperty('--decor-d', null);

            searchBlock.style.display = 'none';
            document.documentElement.style.overflow = null;
            setTimeout(() => {
                searchMenu.style.top = null;
                headerIcon.style.opacity = null;
                nav.style.opacity = null;
                headerSection.style.setProperty('--decor-op', null);
            }, 300);
            });
    });

}