import { getData } from "../../utils.js";

export function mainPageFilters() {
    const sections = document.querySelectorAll('.__index__filters_section');
    if(sections.length > 0) {
        sections.forEach(section=>{
            const filterBtns = section.querySelectorAll('.__index__filters_button');
            const blocks = section.querySelectorAll('.__index__filters_block');
            filterBtns.forEach(btn=>{
                const filterParam = btn.dataset.filter;
                btn.addEventListener('click',()=>{
                    filterBtns.forEach(nonActiveBtn=>{
                        nonActiveBtn.classList.remove('link_active');
                    });
                    btn.classList.add('link_active');
                    blocks.forEach(block=>{
                        const dataBlock = block.dataset.block;
                        if(filterParam == dataBlock) {
                            block.classList.remove('hidden');
                        } else {
                            block.classList.add('hidden');
                        };
                    });
                });
            });
        });
    }
}
export function mainPageNews() {
    const section = document.querySelector('.blog-global-section');
    if(section) {
        const buttons = section.querySelectorAll('.__index__filters_news_button');
        const block = section.querySelector('.__index__filters_news_block');
        const blockMobile = section.querySelector('.__index__filters_news_block_mobile');
        if(buttons.length > 0) {
            buttons.forEach(btn=>{
                let slug = '';
                if(btn.dataset.filter) slug = btn.dataset.filter;
                btn.addEventListener('click',()=>{
                    buttons.forEach(nonActiveBtn=>{
                        nonActiveBtn.classList.remove('link_active');
                    });
                    btn.classList.add('link_active');
                    getData(`/api/articles/?category=${slug}`)
                    .then(data=>{
                        if(data.results && data.results.length > 0) {
                            window.innerWidth > 769 ? block.innerHTML = '' : blockMobile.innerHTML = '';
                            data.results.forEach((result, index)=>{
                                if(window.innerWidth > 769 && index < 2) {
                                    const card = document.createElement('a');
                                    card.classList.add('blog-card');
                                    let image = '/static/assets/images/stub.webp';
                                    let category = '';
                                    if(result.category) category = `
                                        <span>${result.category}</span>
                            
                                        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.970186 3.02982C0.429054 2.99803 0 2.54912 0 2C0 1.45088 0.429054 1.00197 0.970186 0.970179C1.00198 0.429034 1.45089 0 2 0C2.54911 0 2.99802 0.429034 3.02981 0.970179C3.57095 1.00197 4 1.45088 4 2C4 2.54912 3.57095 2.99803 3.02981 3.02982C2.99802 3.57097 2.54911 4 2 4C1.45089 4 1.00198 3.57097 0.970186 3.02982Z" fill="#1D1D1C"/>
                                        </svg>
                                    `;
                                    if(result.image) image = result.image;
                                    card.href = `/articles/${result.slug}/`;
                                    card.innerHTML = `
                                    <div class="img-variant img-variant__16-9">
                                        <img src="${image}" alt="blog-img">
                                    </div>
                                    <div class="blog-card__info-block">
                                        <div class="blog-card__title-block">
                                
                                            <span>${result.name}</span>
                                
                                            <div class="blog-card__title-block__sub-title">
                                                ${result.content}
                                            </div>
                                
                                        </div>
                                        <div class="blog-card__date-block">
                                
                                            ${category}
                                
                                            <div class="blog-card__date-block__date">
                                                ${result.created_at}
                                            </div>
                                
                                        </div>
                                    </div>
                                    `;
                                    block.appendChild(card);
                                } else if(index < 2) {
                                    const card = document.createElement('div');
                                    card.classList.add('swiper-slide');
                                    let image = '/static/assets/images/stub.webp';
                                    let category = '';
                                    if(result.category) category = `
                                        <span>${result.category}</span>
                            
                                        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.970186 3.02982C0.429054 2.99803 0 2.54912 0 2C0 1.45088 0.429054 1.00197 0.970186 0.970179C1.00198 0.429034 1.45089 0 2 0C2.54911 0 2.99802 0.429034 3.02981 0.970179C3.57095 1.00197 4 1.45088 4 2C4 2.54912 3.57095 2.99803 3.02981 3.02982C2.99802 3.57097 2.54911 4 2 4C1.45089 4 1.00198 3.57097 0.970186 3.02982Z" fill="#1D1D1C"/>
                                        </svg>
                                    `;
                                    if(result.image) image = result.image;
                                    let link = `/articles/${result.slug}/`;
                                    card.innerHTML = `
                                        <a href="${link}" class="blog-card">
                                            <div class="img-variant img-variant__16-9">
                                                <img src="${image}" alt="blog-img">
                                            </div>
                                            <div class="blog-card__info-block">
                                                <div class="blog-card__title-block">
                                        
                                                    <span>${result.name}</span>
                                        
                                                    <div class="blog-card__title-block__sub-title">
                                                        ${result.content}
                                                    </div>
                                        
                                                </div>
                                                <div class="blog-card__date-block">
                                        
                                                    ${category}
                                        
                                                    <div class="blog-card__date-block__date">
                                                        ${result.created_at}
                                                    </div>
                                        
                                                </div>
                                            </div>
                                        </a>
                                    `;
                                    blockMobile.appendChild(card);
                                }
                            });
                        };
                    });
                });
            });
        };
    };
}