import {debounce, getData} from "./utils.js";

export function search() {
    const searchInputs = document.querySelectorAll('#search, #searchMobile');

    if (searchInputs.length === 0) return

    searchInputs.forEach((input) => {
        input.addEventListener('input', event => {
            event.preventDefault();
            debouncedGetProducts(event.target.value);
        });
    });


}

const debouncedGetProducts = debounce(getProducts, 300);

function getProducts(searchValue) {
    const emptySection = document.querySelector('.__empty-search-result-section');
    const resultSection = document.querySelector('.__search-result-section');
    const searchHelpSection = document.querySelector('.__search-help');

    toggleSectionResult(emptySection, "hide");
    toggleSectionResult(resultSection, "hide");

    if (!searchValue.trim()) {
        toggleSectionResult(searchHelpSection, "show");
        return;
    }

    getData(`/api/search/?q=${searchValue}`)
        .then(res => {
            toggleSectionResult(searchHelpSection, "hide");
            if (!res.products.length > 0) {
                toggleSectionResult(emptySection, "show");
                document.removeEventListener('keyup', addFastResultListener.addEnterListener);
                return;
            }

            resultSection.innerHTML = '';
            res.products.forEach(product => {
                addSearchResult(product);
            });
            toggleSectionResult(resultSection, 'show');
            addFastResultListener(res.products[0]);
        });
}

function addFastResultListener(product) {
    document.addEventListener('keyup', addEnterListener);

    const searchIcons = document.querySelectorAll('.__searchIcon');
    searchIcons.forEach(searchIcon => {
        searchIcon.removeEventListener('click', redirectToProduct);
        searchIcon.addEventListener('click', redirectToProduct);
    });

    function addEnterListener(e) {
        if (e.key === 'Enter') {
            redirectToProduct();
        }
    }

    function redirectToProduct() {
        window.location.href = `/product/${product.slug}/`;
    }


}


function toggleSectionResult(section, state) {
    switch (state) {
        case "show":
            section.classList.remove('d-none');
            break;
        case "hide":
            section.classList.add('d-none');
            break;
    }
}

function addSearchResult(data) {
    const container = document.querySelector('.__search-result-section');

    const anchor = document.createElement('a');
    anchor.href = `/product/${data.slug}/`;
    anchor.className = 'header-section__search-menu__search-results__result';

    const nameDiv = document.createElement('div');
    nameDiv.className = 'header-section__search-menu__search-results__result__name';
    nameDiv.textContent = data.name;


    const icon = document.createElement('i');
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                    <path d="M4.97656 5.99987L0.851562 1.87487L2.0299 0.696533L7.33323 5.99987L2.0299 11.3032L0.851562 10.1249L4.97656 5.99987Z"
                          fill="#6D6B6B"/>
                </svg>`;
    icon.innerHTML = svg;

    anchor.appendChild(nameDiv);
    anchor.appendChild(icon);

    container.appendChild(anchor);
}