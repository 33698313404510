import { modifications } from './modifications.js';
import { pageBack } from './pageBack.js';
import { zoomButtons } from './zoomButtons.js';

function init() {
    zoomButtons();
    modifications();
    pageBack();
}

export {
    init   
}