import { addInWishlist } from "./addInWishlist.js";
import { checkAllCardsForWishlist } from "./checkAllCardsForWishlist.js";

function init() {
    addInWishlist()
    checkAllCardsForWishlist();
}

export {
    init
}