export function mainHero() {
    const hero = document.querySelector('.main-hero-section');

    if(hero) {
        const topHeader = document.querySelector('.header-section__top-header');
        const header = document.querySelector('.header-section__container');

        const castle = document.querySelector('.main-hero-section__castle');
        const heroBg = document.querySelector('.main-hero-section__main-hero-bg');
        const title = document.querySelector('.main-hero-section__title');
        const btn = document.querySelector('.main-hero-section__btn-block');
        const bgFirst = document.querySelector('.main-hero-section__bg-first');
        const bgSecond = document.querySelector('.main-hero-section__bg-second');

        gsap.set(topHeader, { opacity: 0, y: '-100%' });
        gsap.set(heroBg, { opacity: 0 });
        gsap.set(title, { opacity: 0 });
        gsap.set(btn, { opacity: 0 });
        gsap.set(header, { y: '-150%' });
        gsap.set(castle, { y: '100%' });
        gsap.set(bgFirst, { y: '100%' });
        gsap.set(bgSecond, { y: '100%' });

        const tl = gsap.timeline();

        tl.to(topHeader, { opacity: 1, y: 0, duration: 1 }, 0)
        .to(castle, { opacity: 1, y: 0, duration: 1 }, 0)
        .to(heroBg, { opacity: 1, duration: .75 }, .75)

        .to(bgFirst, { opacity: 1, y: 0, duration: .75 }, .5)
        .to(bgSecond, { opacity: 1, y: 0, duration: .75 }, .75)

        .to(title, { opacity: 1, duration: .75 }, 1)
        .to(btn, { opacity: 1, duration: .75 }, 1.1)
        .to(header, { y: 0, duration: .75 }, 1)

        ScrollTrigger.create({
            trigger: hero,
            start: "top bottom",
            onEnter: () => tl,
        });
    }
}