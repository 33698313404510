import { sendData } from "../utils.js";

export function moveToOrder() {

    const container = document.querySelector('.basket-section__items-list');
    if(container) {
        const orderButtons = document.querySelectorAll('.__move-to-order-btn');

        orderButtons.forEach(btn => {
            btn.addEventListener('click', function() {
                const cards = container.querySelectorAll('.basket-card');
                let productData = [];
                
                cards.forEach(card => {
                    const selector = card.querySelector('.__card_selector');

                    if(selector.checked) {
                        productData.push({
                            'product': parseInt(card.dataset.id),
                            'quantity': card.querySelector('.counter input').value
                        });
                    }
                });
                if(productData.length > 0) {
                    sendData(productData, '/api/order/move-to-order/')
                    .then(data => {
                        let orderId = data.order_id;
                        window.location.href = `/order/edit-order/?order_id=${orderId}`
                    });
                }
            });
        });
    }

    const menuBtn = document.querySelector('.__move-to-order-btn-menu');
    if(menuBtn) {
        menuBtn.addEventListener('click',()=>{
            const basketInMenu = document.querySelector('.__basket_in_menu');
            const counters = basketInMenu.querySelectorAll('.counter');
            let productData = [];

            counters.forEach(counter=>{
                productData.push({
                    'product': parseInt(counter.dataset.id),
                    'quantity': counter.querySelector('input').value
                });
            });

            if(productData.length > 0) {
                sendData(productData, '/api/order/move-to-order/')
                .then(data => {
                    let orderId = data.order_id;
                    window.location.href = `/order/edit-order/?order_id=${orderId}`
                });
            }
        });
    };
}