export function modifications() {   
    const inputs = document.querySelectorAll('[data-modification-id] input')
    inputs.forEach(input => {
        input.addEventListener('change', combineIds)
    })
}

function combineIds() {
    const inputs = document.querySelectorAll('[data-modification-id] input:checked');
    const variableCard = document.querySelector('#groupId');
    const ids = [];
    let groupId = variableCard.value

    inputs.forEach(input => {
        ids.push(input.parentNode.dataset.modificationId);
    });

    getProductModifications(ids, groupId)
        .then(res => {
            window.location.href = `/product/${res.slug}/`;
        })
}

const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
async function getProductModifications(ids, groupId) {
    const response = await fetch('/api/products/get-modification/', {
        method: "POST",
        headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': "application/json",
        },
        body: JSON.stringify({"ids": ids, "group_id": groupId}
        ),
    });

    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}