import { showBasketInMenu } from "../basket/showBasketInMenu.js";
import { closeBurgerMobile } from "./burger-menu.js";
import { showWishlistInMenu } from "../wishlist/showWishlistInMenu.js";
import { userLoggedIn } from "../cookies.js";
import { removeFromBasket } from "../basket/basketFunctions.js";
import { hideTopMenu } from "./header-menus.js";

export function globalMenu() {
    const globalMenu = document.querySelector('[data-header-hover-menu="global"]');
    const inset = document.querySelector('.__inset');
    const closeBtns = document.querySelectorAll('.__close_global_btn');

    if(globalMenu) {
        const btns = document.querySelectorAll('[data-global-block]');
        const blocks = globalMenu.querySelectorAll('[data-global-block-menu]');
        const clickBtns = document.querySelectorAll('[data-global-block-click]');

        let blockShown = false;

        btns.forEach(btn=>{
            const dataAttr = btn.dataset.globalBlock;
            const block = globalMenu.querySelector(`[data-global-block-menu="${dataAttr}"]`);
            btn.addEventListener('mouseover',()=>{
                if(!blockShown) {
                    blockShown = true;
                    blocks.forEach(hiddenBlock=>{
                        if(hiddenBlock !== block) hideBlock(hiddenBlock);
                    });
                    showBlock(globalMenu, dataAttr);
                    setTimeout(() => {
                        blockShown = false;
                    }, 300);
                }
            });
        });
        clickBtns.forEach(btn=>{
            const dataAttr = btn.dataset.globalBlockClick;
            const block = globalMenu.querySelector(`[data-global-block-menu="${dataAttr}"]`);
            btn.addEventListener('click',()=>{
                globalMenu.removeEventListener('mouseleave',hideGlobalMenuListener);
                blocks.forEach(hiddenBlock=>{
                    if(hiddenBlock !== block) hideBlock(hiddenBlock);
                });
                showGlobalMenu(globalMenu, dataAttr);
                if(btn.dataset.globalBlockClick == 'auth') {
                    globalMenu.addEventListener('mouseleave',hideGlobalMenuListener);
                }
            });
        });
        inset.addEventListener('click',hideGlobalMenuSetHover);
        closeBtns.forEach(btn=>{
            btn.addEventListener('click',hideGlobalMenuSetHover);
        });
        if(userLoggedIn()) {
            const cards = globalMenu.querySelectorAll('.product-card');
            if(cards.length > 0) {
                cards.forEach(card=>{
                    const deleteCardBtn = card.querySelector('.__delete_product');
                    const cardId = card.dataset.id;
                    if(deleteCardBtn) {
                        deleteCardBtn.addEventListener('click',()=>{
                            const hr = card.nextElementSibling;
                            hr.parentNode.removeChild(hr);
                            card.parentNode.removeChild(card);
                            removeFromBasket(cardId);
                        });
                    };
                });
            };
        };
    };
}


export function showGlobalMenu(menu, dataAttr) {
    const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');
    hoverMenus.forEach(notHovered=>{
        hideTopMenu(notHovered);
    });

    const burgerMenu = document.querySelector('.header-section__mobile-menu');
    menu.classList.add('active');

    const inset = document.querySelector('.__inset');
    inset.classList.add('show');

    burgerMenu && closeBurgerMobile();
    showBlock(menu, dataAttr);
}
function showBlock(menu, dataAttr) {
    const globalBlocks = menu.querySelectorAll('[data-global-block-menu]');
    const btns = menu.querySelectorAll('[data-global-block]');
    
    globalBlocks.forEach(block=>{
        const blockAttr = block.dataset.globalBlockMenu;
        if(dataAttr == blockAttr) {
            block.dataset.menuState = 'open';
        } else {
            setTimeout(() => {
                hideBlock(block);
            }, 100);
        }
    });

    btns.forEach(hiddenBtn=>{
        if(hiddenBtn.dataset.globalBlock == dataAttr) {
            hiddenBtn.classList.add('icon-button_active');
        } else { 
            hiddenBtn.classList.remove('icon-button_active');
        }
    });


    if(dataAttr == 'cart') {
        showBasketInMenu();
    }
    if(dataAttr == 'wishlist') {
        showWishlistInMenu();
    }
}

function hideGlobalMenuSetHover() {
    const globalMenu = document.querySelector('[data-header-hover-menu="global"]');
    const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');

    hoverMenus.forEach(menu=>{
        const dataAttr = menu.dataset.headerHoverMenu;
        if(dataAttr == 'product' || dataAttr == 'category') {
            hideTopMenu(menu);
        };
    });
    globalMenu.addEventListener('mouseleave',hideGlobalMenuListener);
    hideGlobalMenu(globalMenu);
}
export function hideGlobalMenuListener() {
    const globalMenu = document.querySelector('[data-header-hover-menu="global"]');
    const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');

    hoverMenus.forEach(menu=>{
        const dataAttr = menu.dataset.headerHoverMenu;
        if(dataAttr == 'product' || dataAttr == 'category') {
            hideTopMenu(menu);
        };
    });
    hideGlobalMenu(globalMenu);
}

function hideGlobalMenu(menu) {
    const btns = menu.querySelectorAll('[data-global-block]');

    menu.classList.remove('active');

    const inset = document.querySelector('.__inset');
    setTimeout(() => {
        inset.classList.remove('show');
    }, 600);
    const globalBlocks = menu.querySelectorAll('[data-global-block-menu]');
    globalBlocks.forEach(block=>{
        setTimeout(() => {
            hideBlock(block);
        }, 600);
    });
    btns.forEach(hiddenBtn=>{
        hiddenBtn.classList.remove('icon-button_active');
    });
}
function hideBlock(block) {
    block.dataset.menuState = 'close';
}