export function familyStatus() {
    const familyField = document.querySelector('.family_status__field');
    if(familyField) {
        const radioButtons = familyField.querySelectorAll('.__select__input');
        const hiddenInput = familyField.querySelector('input[name=family_status]');

        radioButtons.forEach((radioButton) => {
            radioButton.addEventListener('click', () => {
                switch (radioButton.id) {
                    case 'singleSelect1':
                        hiddenInput.value = 'married';
                    break;
                    case 'singleSelect2':
                        hiddenInput.value = 'not_married';
                    break;
                    case 'singleSelect3':
                        hiddenInput.value = 'divorced';
                    break;
                }
            });
        });
    }
}