import { checkForEmptyUnreg } from './checkForEmptyUnreg.js';
import { dinamicPrices } from './dinamicPrices.js';
import { cardsDeletions } from './cardsDeletions.js';
import { showCards } from './showCards.js';

function init() {
    checkForEmptyUnreg();

    dinamicPrices();
    cardsDeletions();
    showCards();
}

export {
    init   
}