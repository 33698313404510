import { mainPageFilters, mainPageNews } from "./filters.js"
import { needLogin } from "./needLogin.js"

function init() {
    mainPageFilters()
    mainPageNews()
    needLogin()
}

export {
    init   
}