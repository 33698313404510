import { getRem } from "./slider.js";

export function link_block () {
    document.addEventListener('DOMContentLoaded', function() {
        const linkBlock = document.querySelector('.article-section__link-block');
        const headers = document.querySelectorAll('article h2');

        if(linkBlock) document.documentElement.style.scrollBehavior = 'smooth';

        headers.forEach((header, index) => {
            const uniqueId = `section-${index + 1}`;
            header.id = uniqueId;  // Присваиваем ID заголовку
    
            // Создаем ссылку для каждого заголовка
            const link = document.createElement('a');
            link.href = `#${uniqueId}`;
            link.classList.add('link', 'link_size_basic', 'link_primary');
            link.textContent = header.textContent;
    
            const span = document.createElement('span');
            span.classList.add('link__line');
            link.appendChild(span);
    
            linkBlock.appendChild(link);
        });
    
        // Функция для обновления активного класса
        function updateActiveLink(entries) {
            if(entries.length > 0) {
                entries.forEach(entry => {
                    const targetId = entry.target.id;
                    const link = document.querySelector(`a[href="#${targetId}"]`);
        
                    if (entry.isIntersecting) {
                        link.classList.add('link_active');
                    } else {
                        link.classList.remove('link_active');
                    }
                });
            }
        }
    
        // Intersection Observer для отслеживания заголовков
        const observer = new IntersectionObserver(updateActiveLink, {
            rootMargin: '0px 0px -72% 0px',  // Настраиваем, когда заголовок считается в зоне видимости
            threshold: 0
        });
    
        headers.forEach(header => {
            observer.observe(header);
        });
    
    });    
}

// Degenerated by GPT