export function blog() {
    const section = document.querySelector('.blog-section');

    if(section) {
        const titles = section.querySelectorAll('.animation-title');
        const btn = section.querySelector('.blog-section__btn-block');
        const cards = section.querySelectorAll('.blog-card');

        titles.forEach(title => {
            gsap.set(title, { opacity: 0, x: '-50%' });
        });

        cards.forEach(card => {
            gsap.set(card, { opacity: 0, y: '-25%' });
        });

        gsap.set(btn, { opacity: 0 });

        const tl = gsap.timeline({ paused: true });

        tl.to(titles, { opacity: 1, x: 0, duration: 1, stagger: .3 }, 0)
        .to(btn, { opacity: 1, duration: 1.25 }, 1.75)
        .to(cards, { opacity: 1, y: 0, duration: 1.25, stagger: .3 }, 1);

        ScrollTrigger.create({
            trigger: section,
            start: "top bottom",
            onEnter: () => tl.play(),
        });
    }
}