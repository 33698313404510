import { setCookie } from '../cookies.js';
import { sendData } from '../utils.js';

export function logout() {
    const logoutBtn = document.querySelector('.__logout_btn');
    if(logoutBtn) {
        logoutBtn.addEventListener('click',()=>{
            sendData(NaN, '/api/logout/')
            .then(res=>{
                setCookie('token', '', { 'max-age': -1 });
                window.location.href = window.location.origin + '/';
            });
        });
    };
}