import * as main from './main/index.js';
import * as basket from './basket/index.js';
import * as wishlist from './wishlist/index.js';
import * as productCard from './product-card/index.js';
import * as support from './support/index.js';
import * as contact from './contact/index.js';

main.init();
basket.init();
wishlist.init();
productCard.init();
support.init();
contact.init();