export function addressSelect() {
    const container = document.querySelector('#order-form');
    if (container) {
        const selector = container.querySelector('.__select__title');
        const input = container.querySelector('input[name="address"]');
        const selectContent = container.querySelector('.__select__content');
  
        selectContent.addEventListener('click', (event) => {
            if (event.target.tagName === 'LABEL') {
                const selectedAddress = event.target.textContent;
                input.value = selectedAddress;
                selector.textContent = selectedAddress;
            }
        });
    }
}