export function headerSwap() {
    const header = document.querySelector('header');

    if(document.location.pathname === '/' || document.location.pathname === '/about/') {
        window.addEventListener('scroll', function() {
            if(window.scrollY > 50) {
                header.classList.remove('inherit-version');
            } else {
                header.classList.add('inherit-version');
            }
        });
    }
}