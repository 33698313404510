import { hideGlobalMenuListener, showGlobalMenu } from "../../header/global-menu.js";
import { getCurrentQuery } from "../../utils.js";

export function needLogin() {
    const currentQueries = getCurrentQuery();
    document.addEventListener('DOMContentLoaded',()=>{
        currentQueries.forEach(queryObject=>{
            if(queryObject['need_login']) {
                setTimeout(() => {
                    const globalMenu = document.querySelector('[data-header-hover-menu="global"]');
                    globalMenu.removeEventListener('mouseleave',hideGlobalMenuListener);
                    showGlobalMenu(globalMenu, 'registration');
                }, 1000);
            };
        });
    })
}