import { btnWishlistFunc } from "./wishlistFunctions.js";

export function addInWishlist() {
    const addBtns = document.querySelectorAll('.__add_in_wishlist');
    if(addBtns.length > 0) {
        addBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                btnWishlistFunc(btn);
            });
        });
    };
};
