import { btnBasketFunc, getBasketFromCookie } from "../../basket/basketFunctions.js";
import { btnWishlistFunc, getWishlistFromCookie } from "../../wishlist/wishlistFunctions.js";

export function addCardProducts(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem => {

        let imagesHtml = elem.photos.map((photo, index) => {
            return `<img src="${photo.photo}" alt="product-img" data-image="${index + 1}">`;
        }).join('');

        if(elem.photos.length < 1) {
            imagesHtml = '<img src="/static/assets/images/stub.webp" alt="product-img">'
        }

        const tabs = elem.photos.map((photo, index) => {
            return `<div class="product-card__tabs__tab" data-image-tab="${index + 1}"></div>`;
        }).join('');

        let priceWithDiscount = `${elem.price} Р`;
        if(elem.discount) {
            priceWithDiscount = `
                ${elem.price} Р
                <span>${elem.base_price} Р</span>
            `;
        }

        const biggerBtn = document.querySelector('.bigger-btn');
        const smallerBtn = document.querySelector('.smaller-btn');

        biggerBtn.addEventListener('click', function() {
            biggerBtn.classList.add('link_active');
            smallerBtn.classList.remove('link_active');

            productCards.forEach(card => {
                card.classList.remove('product-card_grid');
            });
        });
        smallerBtn.addEventListener('click', function() {
            biggerBtn.classList.remove('link_active');
            smallerBtn.classList.add('link_active');

            productCards.forEach(card => {
                card.classList.add('product-card_grid');
            });
        });
        
        const div = document.createElement('div');

        if(smallerBtn.classList.contains('link_active')) {
            div.classList.add('product-card', 'product-card_grid');
        } else {
            div.classList.add('product-card');
        }

        div.innerHTML = `
            <div class="product-card__img-block img-variant img-variant__1-1">
                ${imagesHtml}
                <a href="/product/${elem.slug}/" class="product-card__tabs">
                    ${tabs}
                </a>

                <div class="product-card__favourite-btn __add_in_wishlist" data-id="${elem.id}">
                    <button class="icon-button icon-button_size_basic icon-button_subdued">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z" fill="#6D6B6B"/>
                        </svg>
                    </button>
                </div>

                <div class="product-card__tag">
                    <div class="tag-elem tag-elem_primary">
                        Популярное
                    </div>
                </div>

            </div>
            <div class="product-card__info-block">
                <a href="/product/${elem.slug}/" class="product-card__title-block">

                    <div class="product-card__title-block__cost-block">
                        ${priceWithDiscount}
                    </div>
            
                    <div class="product-card__title-block__product-name">
                        ${elem.name}
                    </div>
                </a>
                <button class="button button_size_small button_accent __add_in_basket" data-id="${elem.id}">
                    <span>В корзину</span>
                    <span>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_63_9893)">
                            <path d="M8.75 8.25V3.75H10.25V8.25H14.75V9.75H10.25V14.25H8.75V9.75H4.25V8.25H8.75Z" fill="#FCF5F7"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_63_9893">
                            <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
        `;

        const basket = getBasketFromCookie();

        const btnBasket = div.querySelector('.__add_in_basket');
        if(btnBasket) {
            btnBasket.addEventListener('click',()=>{
                btnBasketFunc(btnBasket);
            });
        };

        if(basket && basket.length > 0) {
            basket.forEach(item=>{
                if(item.product == elem.id) {
                    btnBasket.click();
                    const newBtn = div.querySelector('.counter');
                    newBtn.querySelector('input').value = item.quantity;

                    const event = new Event('input', {
                        bubbles: true
                    });

                    newBtn.querySelector('input').dispatchEvent(event)
                };
            });
        }

        const wishlist = getWishlistFromCookie();

        const wishlistBtn = div.querySelector('.__add_in_wishlist');
        wishlistBtn.addEventListener('click',()=>{
            btnWishlistFunc(wishlistBtn);
        });

        if(wishlist && wishlist.length > 0) {
            wishlist.forEach(item=>{
                if(item.product == elem.id) {
                    wishlistBtn.querySelector('.icon-button').classList.add('icon-button_active');
                    wishlistBtn.querySelector('.icon-button').innerHTML = `
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z" fill="black"/>
                        </svg>
                    `;
                };
            });
        };

        fragment.appendChild(div);
    })
    section.appendChild(fragment);

//////////////////////////////////////////

    const productCards = section.querySelectorAll('.product-card');

    let productLength = 0;

    productCards.forEach(card => {
        productLength++
        const tabsCard = card.querySelectorAll('.product-card__tabs__tab');
        const imagesCard = card.querySelectorAll('.product-card__img-block img');
    
        tabsCard.forEach(tab => {
            tab.addEventListener('mouseenter', function() {
                imagesCard.forEach(img => {
                    if(tab.dataset.imageTab === img.dataset.image) {
                        img.style.opacity = '1';
                    } else {
                        img.style.opacity = '0';
                    }
                });
            });
        });
    });

///////////////////////////////////////////////

    const elemCount = document.querySelector('.catalog-section__btn-block span');
    elemCount.innerHTML = productLength;

}