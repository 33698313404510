import { hideGlobalMenuListener } from "./global-menu.js";
import { hideTopMenu } from "./header-menus.js";

export function burgerMenu() {
    const burgerBtn = document.querySelectorAll('.burger-btn');
    const filterMenu = document.querySelector('.filter-menu');
    const burgerMenu = document.querySelector('.header-section__mobile-menu');
    const burgerClose = document.querySelector('.burger-close');
    const burgerCloseMobile = document.querySelector('.burger-close-mobile');
    const backBurger = document.querySelectorAll('.back-burger');
    const headerSection = document.querySelector('.header-section');
    const inset = document.querySelector('.__inset');
    const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');

    const header = document.querySelector('header');

    if (burgerBtn.length > 0) {
        burgerBtn.forEach(btn => {
            btn.addEventListener('click', () => {
                document.documentElement.style.overflow = 'hidden';
                if (window.innerWidth <= 768) {
                    burgerMenu.style.bottom = 0;
                    headerSection.style.setProperty('--decor-op', 0);
                    btn.style.display = 'none';
                    burgerCloseMobile.style.display = 'flex';
                    setTimeout(() => {
                        headerSection.style.setProperty('--decor-d', 'none');
                        if (header.classList.contains('inherit-version')) header.classList.remove('inherit-version');
                    }, 300);
                    hideGlobalMenuListener();
                    if (filterMenu) filterMenu.classList.remove('active');
                } else {
                    burgerMenu.style.left = 0;
                    inset.classList.add('show');
                    hoverMenus.forEach(menu=>{
                        hideTopMenu(menu);
                    });
                    setTimeout(() => {
                        inset.classList.add('show');
                    }, 300);
                }
            });
        });
        backBurger.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');
                hoverMenus.forEach(menu=>{
                    hideTopMenu(menu);
                });
                document.documentElement.style.overflow = 'hidden';
                burgerBtn.forEach(btn=>{
                    btn.style.display = 'none';
                });
                if(window.innerWidth <= 768) {
                    burgerMenu.style.bottom = 0;
                    headerSection.style.setProperty('--decor-op', 0);
                    burgerCloseMobile.style.display = 'flex';
                    hideGlobalMenuListener();
                    if(filterMenu) filterMenu.classList.remove('active');
                } else {
                    burgerMenu.style.left = 0;
                    inset.classList.add('show');
                    setTimeout(() => {
                        inset.style.opacity = .32;
                    }, 300);
                };
            });
        });

        burgerClose.addEventListener('click', ()=> {
            document.documentElement.style.overflow = null;
            burgerMenu.style.left = null;
            inset.style.opacity = null;
            setTimeout(() => {
                inset.classList.remove('show');
            }, 200);
        });
        burgerCloseMobile.addEventListener('click', ()=> {
            closeBurgerMobile()
        });
        inset.addEventListener('click', ()=> {
            burgerClose.click()
        });
    }
}

export function closeBurgerMobile() {
    const burgerBtn = document.querySelectorAll('.burger-btn');
    const burgerMenu = document.querySelector('.header-section__mobile-menu');
    const burgerCloseMobile = document.querySelector('.burger-close-mobile');
    const headerSection = document.querySelector('.header-section');

    document.documentElement.style.overflow = null;
    burgerMenu.style.bottom = null;
    burgerCloseMobile.style.display = 'none';
    burgerBtn.forEach(btn => btn.style.display = 'flex');
    headerSection.style.setProperty('--decor-d', 'flex');
    setTimeout(() => {
        headerSection.style.setProperty('--decor-op', 1);
    }, 200);
}