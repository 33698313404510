gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

import { mainHero } from "./main-hero.js";
import { category } from "./category-section.js";
import { products } from "./products-section.js";
import { blog } from "./blog-section.js";
import { jewelry } from "./jewelry-section.js";
import { PageTransition } from "./page-transition.js";

export function scrollTrigger() {
    mainHero()
    category()
    products()
    blog()
    jewelry()
    PageTransition()
}