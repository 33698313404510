import { closeBurgerMobile } from "./burger-menu.js";
import { hideGlobalMenuListener, showGlobalMenu } from "./global-menu.js";

export function headerMenus() {
    const hoverBtns = document.querySelectorAll('[data-header-hover]');
    const clickBtns = document.querySelectorAll('[data-header-click]');
    const hoverMenus = document.querySelectorAll('[data-header-hover-menu]');
    const globalMenu = document.querySelector('[data-header-hover-menu="global"]');

    hoverBtns.forEach(btn=>{
        const dataAttr = btn.dataset.headerHover;
        const menu = document.querySelector(`[data-header-hover-menu="${dataAttr}"]`);
        if(btn) {
            btn.addEventListener('mouseover',()=>{
                setHeaderDefault();
                if(dataAttr == 'product' || dataAttr == 'category') {
                    hoverMenus.forEach(notHovered=>{
                        if(notHovered !== menu) hideTopMenu(notHovered);
                    });
                    showTopMenu(menu);
                } else if(dataAttr == 'wishlist' || dataAttr == 'cart' || dataAttr == 'auth' || dataAttr == 'account') {
                    showGlobalMenu(globalMenu, dataAttr);
                };
            });
        };
        if(menu) {
            menu.addEventListener('mouseleave',()=>{
                if(dataAttr == 'product' || dataAttr == 'category') {
                    hideTopMenu(menu);
                };
            });
        };
    });
    clickBtns.forEach(btn=>{
        const dataAttr = btn.dataset.headerClick;
        const menu = document.querySelector(`[data-header-hover-menu="${dataAttr}"]`);
        if(btn) {
            btn.addEventListener('click',()=>{
                setHeaderDefault(); 
                hoverMenus.forEach(notHovered=>{
                    if(notHovered !== menu) hideTopMenu(notHovered);
                });
                showTopMenu(menu);
            });
        };
    });
    if(globalMenu) {
        globalMenu.addEventListener('mouseleave',hideGlobalMenuListener);
    };
    
}

function setHeaderDefault() {
    const header = document.querySelector('header');
    if(header.classList.contains('inherit-version')) header.classList.remove('inherit-version');
}

function showTopMenu(menu) {
    const burgerMenu = document.querySelector('.header-section__mobile-menu');
    document.documentElement.style.overflow = null;
    burgerMenu.style.left = null;
    closeBurgerMobile();
    menu.style.maxHeight = menu.scrollHeight + 'px';
    const containers = menu.querySelectorAll('.top-menu-container');
    if(containers.length > 0) {
        const inset = document.querySelector('.__inset');
        inset.classList.add('show');
        document.querySelector('header').style.zIndex = 51;
        containers.forEach(container=>{
            setTimeout(() => {
                container.style.opacity = 1;
            }, 600);
        });
    }

    const categoryItems = menu.querySelectorAll('.category-product');
    if(categoryItems.length > 0) {
        categoryItems.forEach((item, index)=>{
            setTimeout(() => {
                item.style.opacity = '1';
                item.style.transform = 'translateY(0)';
            }, (index + 1) * 300);
        });
    }
}
export function hideTopMenu(menu) {
    menu.style.maxHeight = null;
    const containers = menu.querySelectorAll('.top-menu-container');
    if(containers.length > 0) {
        containers.forEach(container=>{
            setTimeout(() => {
                container.style.opacity = 1;
            }, 600);
        });
    }

    const inset = document.querySelector('.__inset');
    inset.classList.remove('show');
    document.querySelector('header').style.zIndex = null;

    const categoryItems = menu.querySelectorAll('.category-product');
    if(categoryItems.length > 0) {
        categoryItems.forEach((item)=>{
            item.style.opacity = null;
            item.style.transform = null;
        });
    }
}