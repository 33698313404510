export function slider() {
    const alsoLike = new Swiper('.also-like-slider',{
        slidesPerView: 1.6,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 5,
                spaceBetween: getRem() * 2,
                slidesOffsetBefore: getRem() * 2,
                slidesOffsetAfter: getRem() * 2,
            }
        },
        navigation: {
            nextEl: ".also-like-slider__next-btn",  
        },
        mousewheel: {
            forceToAxis: true
        }
    });
    const categorySlider = new Swiper('.category-menu_slider',{
        slidesPerView: 2.4,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            520: {
                slidesPerView: 4.2,
            }
        },
        navigation: {
            nextEl: ".header-section__category-menu_slider__next-btn",  
        },
        mousewheel: {
            forceToAxis: true
        }
    });
    var teamSwiper = new Swiper(".teamSwiper", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 5,
        spaceBetween: getRem()*2,
        breakpoints: {
            640: {
                slidesPerView: 5,
                spaceBetween: getRem()*2,
            },
            320: {
                slidesPerView: 1.5,
                spaceBetween: getRem() * 1,
            }
        }
    });
    var newsSwiper = new Swiper(".newsSwiper", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1.2,
        spaceBetween: getRem()*2,
    });

    const productsSlider = new Swiper('.__products-block_slider',{
        slidesPerView: 1.6,
        spaceBetween: 12,
        centeredSlides: true,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".__products-block_slider__next-btn",  
        },
        mousewheel: {
            forceToAxis: true
        },
        breakpoints: {
            769: {
                slidesPerView: 5,
                spaceBetween: getRem() * 2,
                centeredSlides: false,
                slidesOffsetBefore: getRem() * 2,
                slidesOffsetAfter: getRem() * 2,
            }
        }
    });
    const productsSliderSecond = new Swiper('.__products-block_slider_second',{
        slidesPerView: 1.6,
        spaceBetween: 12,
        centeredSlides: true,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter:getRem() * 1,
        navigation: {
            nextEl: ".__products-block_slider_second__next-btn",  
        },
        mousewheel: {
            forceToAxis: true
        },
        breakpoints: {
            769: {
                slidesPerView: 5,
                spaceBetween: getRem() * 2,
                centeredSlides: false,
                slidesOffsetBefore: getRem() * 2,
                slidesOffsetAfter: getRem() * 2,
            }
        }
    });

    const blogsSlider = new Swiper('.blog-block_slider',{
        slidesPerView: 1.22,
        spaceBetween: 12,
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 12,
        navigation: {
            nextEl: ".blog-section__blog-block_slider__next-btn",  
        },
        mousewheel: {
            forceToAxis: true
        },
    });

    const productSwiper = new Swiper(".productSwiper", {
        pagination: {
            el: ".swiper-pagination",
        },
        slidesPerView: 1,
        spaceBetween: getRem() * 1,
    });
}

export function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2);
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { slider } from "./путь/к/файлу/slider.js";

// Активация: slider();