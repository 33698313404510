import { btnBasketFunc } from "../../basket/basketFunctions.js";
import { btnWishlistFunc } from "../../wishlist/wishlistFunctions.js";
import {productCard} from "../../product-card.js";

export async function addCard(data, container) {
    const card = document.createElement('div');
    card.classList.add('product-card', 'product-card_grid')

    let priceWithDiscount = '';
    if(data.discount) {
        priceWithDiscount = `
            ${data.price} Р
            <span>${data.base_price} Р</span>
        `;
    }else {
        priceWithDiscount = `
            ${data.base_price} Р
        `;
    };

    card.innerHTML = `
         <div class="product-card__img-block img-variant img-variant__1-1">

            <a href="/product/${data.slug}/" class="product-card__tabs">

            </a>

            <div class="product-card__favourite-btn __add_in_wishlist" data-id="${data.id}">
                <button class="icon-button icon-button_size_basic icon-button_subdued icon-button_active">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z" fill="black"/>
                    </svg>
                </button>
            </div>

            <div class="product-card__tag">
                <div class="tag-elem tag-elem_primary">
                    Популярное
                </div>
            </div>

        </div>
        <a href="/product/${data.slug}" class="product-card__title-block">

            <div class="product-card__title-block__cost-block">
                ${priceWithDiscount}
            </div>

            <div class="product-card__title-block__product-name">
                ${data.name}
            </div>
            
        </a>
        <button class="button button_size_small button_accent __add_in_basket" data-id="${data.id}">
            <span>В корзину</span>
            <span>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_63_9893)">
                    <path d="M8.75 8.25V3.75H10.25V8.25H14.75V9.75H10.25V14.25H8.75V9.75H4.25V8.25H8.75Z"
                            fill="#FCF5F7"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_63_9893">
                    <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                    </defs>
                </svg>
            </span>
        </button>
    `;

    const addInBasketBtn = card.querySelector('.__add_in_basket');
    addInBasketBtn.addEventListener('click',()=>{
        btnBasketFunc(addInBasketBtn);
    });
    
    const addInWishlistBtn = card.querySelector('.__add_in_wishlist');
    addInWishlistBtn.addEventListener('click',()=>{
        btnWishlistFunc(addInWishlistBtn);
    });

    if(data.photos.length > 0) {
        const imgBlock = card.querySelector('.product-card__img-block');
        const tabs = card.querySelector('.product-card__tabs');
        data.photos.forEach((elem, index)=>{
            const img = document.createElement('img');
            const tab = document.createElement('div');

            tab.classList.add('product-card__tabs__tab');
            tab.dataset.imageTab = index + 1;

            img.dataset.image = index + 1;
            img.src = elem.photo;

            
            imgBlock.appendChild(img);
            tabs.appendChild(tab);
        });
    } else {
        const imgBlock = card.querySelector('.product-card__img-block');
        const img = document.createElement('img');

        img.src = '/static/assets/images/stub.webp';

        imgBlock.appendChild(img);
    }

    if(container) {
        container.appendChild(card);
    }

    productCard()
}