import { z } from "../libraries/zod.js"
import { getBasketFromCookie, removeFromBasket } from "./basket/basketFunctions.js";
import { setCookie, userLoggedIn } from "./cookies.js";
import { hideGlobalMenuListener } from "./header/global-menu.js";
import { successDialog } from "./success-dialog.js";
import { findParentWithClass, getCurrentQuery, sendData, sendPatch } from "./utils.js";
import { getWishlistFromCookie, removeFromWishlist } from './wishlist/wishlistFunctions.js';


function getTextInsideSquareBrackets(text) {
    const startIndex = text.indexOf('['); // Находим индекс первой открывающей скобки
    const endIndex = text.lastIndexOf(']'); // Находим индекс последней закрывающей скобки
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) { // Проверяем, что скобки найдены и первая скобка находится перед последней
        return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
    } else {
        return ''; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
    }
}

const schemaRegister = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
    phone: z.string()
        .min(18,{message: "Проверьте номер телефона"}),
    password: z.string()
        .min(4,{message: "слишком коротко"}),
    repeat_password: z.string()
        .min(4,{message: "слишком коротко"}),
}).refine(
    (data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password","repeat_password"], // path of error
});
const schemaLogin = z.object({
    phone: z.string()
        .min(18,{ message: "Invalid phone number" }),
    password: z.string()
        .min(4,{message: "слишком коротко"}),
});
const schemaRecoveryRequest = z.object({
    email: z.string()
        .email({ message: "Invalid email address" }),
});
const schemaRecoveryPass = z.object({
    password: z.string()
        .min(4,{message: "слишком коротко"}),
    repeat_password: z.string()
        .min(4,{message: "слишком коротко"}),
}).refine(
    (data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password","repeat_password"], // path of error
});

const schemaUpdateInfo = z.object({
    email: z.string().optional(),
    phone: z.string().optional(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    gender: z.string().optional(),
    family_status: z.string().optional(),
});

const schemaOrderInfo = z.object({
    email: z.string().email(),
    phone: z.string().min(18),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    comment: z.string().optional(),
    save_in_profile: z.boolean().optional(),
    date_delivery: z.string(),
    time_delivery: z.string().max(10),
    address: z.string().min(1),
});

const schemaAddress = z.object({
    address: z.string().max(256),
    house: z.string().max(256),
    approach: z.string().max(256),
    level: z.string().max(2147483648),
    intercom: z.string().max(2147483648),
});

const schemaAddressChange = z.object({
    house: z.string().max(256),
    approach: z.string().max(256),
    level: z.string().max(2147483648),
    intercom: z.string().max(2147483648),
});

const schemaMailing = z.object({
    email: z.string().email(),
});

const schemaFAQFeedback = z.object({
    email: z.string().email(),
    phone: z.string().min(18),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    message: z.string()
});

export function validation() {
    const registrationForm = document.querySelector('#registration-form');
    if(registrationForm) {
        formValidate(
            registrationForm,
            schemaRegister,
            (body)=>{
                sendData(body, '/api/registration/')
                .then(res=>{
                    setCookie('token', res.token);
                    setCookieAuthUser();
                    window.location.href = '/profile/';
                });
            }
        )
    }
    const loginForm = document.querySelector('#login-form');
    if(loginForm) {
        formValidate(
            loginForm,
            schemaLogin,
            (body)=>{
                sendData(body, '/api/login/')
                .then(res=>{
                    setCookie('token', res.token);
                    setCookieAuthUser();
                    window.location.href = '/profile/';
                }).catch(error=>{
                    loginForm.querySelectorAll('input').forEach(input=>input.classList.add('error'));
                })
            }
        )
    }
    const recoveryRequestForm = document.querySelector('#recovery-request-form');
    if(recoveryRequestForm) {
        formValidate(
            recoveryRequestForm,
            schemaRecoveryRequest,
            (body)=>{
                sendData(body, '/api/change-password/make-recovery-request/')
                .then(res=>{

                });
            }
        )
    }
    const recoveryPassForm = document.querySelector('#recovery-password-form');
    if(recoveryPassForm) {
        formValidate(
            recoveryPassForm,
            schemaRecoveryPass,
            (body)=>{
                let info = [
                    ...body
                ];
                const query = getCurrentQuery();
                info.uid = query.uid;
                info.token = query.token;
                sendData(info, '/api/change-password/recovery-pasword/')
                .then(res=>{

                });
            }
        )
    }

    const orderForm = document.querySelector('#order-form');
    if(orderForm) {
        formValidate(
            orderForm,
            schemaOrderInfo,
            (body) => {
                const checkbox = orderForm.querySelector('input[name="save_in_profile"]');
                body.save_in_profile = checkbox.checked;

                const params = new URLSearchParams(window.location.search);
                const orderId = params.get('order_id');
                if(body.save_in_profile) {
                    if(userLoggedIn()) {
                        sendPatch(body, '/api/profile/update-user-data/')
                        .then(res => {
                            sendPatch(body, `/api/order/${orderId}/`)
                            .then(res => {
                                window.location.href = `/order/success-order/?order_id=${orderId}`;
                            });
                        });
                    }
                } else {
                    sendPatch(body, `/api/order/${orderId}/`)
                    .then(res => {
                        const allProducts = document.querySelectorAll('.__order_card');
                        if(allProducts.length > 0) {
                            allProducts.forEach(product=>{
                                const id = parseInt(product.dataset.id);
                                removeFromBasket(id);
                            });
                        };
                        window.location.href = `/order/success-order/?order_id=${orderId}`;
                    });
                }
            }
        );
    }

    const userInfoUpdateForm = document.querySelector('#user-info-update-form');
    if(userInfoUpdateForm) {
        formValidate(
            userInfoUpdateForm,
            schemaUpdateInfo,
            (body) => {
                const birthdayInp = userInfoUpdateForm.querySelector('input[name="birthday-hide"]');
                if(birthdayInp.value !== '') {
                    body.birthday = birthdayInp.value;
                } else {
                    delete body.birthday;
                }

                const familyStatusInput = userInfoUpdateForm.querySelector('input[name="family_status"]')
                if(familyStatusInput.value == 'None') body.family_status = '';

                sendPatch(body, '/api/profile/update-user-data/')
                .then(res => {
                    successDialog('upd-user-info');
                    const btn = userInfoUpdateForm.querySelector('[name="button"]')
                    btn.disabled = true;
                });
            }
        );
    }

    const createAddressForm = document.querySelector('#create-address-form');
    if(createAddressForm) {
        formValidate(
            createAddressForm,
            schemaAddress,
            (body) => {
                let inputAddress = createAddressForm.querySelector('input[name="address"]');

                if(userLoggedIn()) {
                    let event = new Event('input', { bubbles: true });
                    body.address = inputAddress.value;
                    inputAddress.dispatchEvent(event);

                    sendData(body, '/api/profile/add-user-address/')
                    .then(res => {
                        hideGlobalMenuListener()
                        window.location.reload();
                    }); 
                } else {
                    const selectBlock = document.querySelector('.order-section__form-block__item__info__select-block');
                    inputAddress = selectBlock.querySelector('input[name="address"]');
                    const addressSelect = selectBlock.querySelector('.__address-select');

                    let event = new Event('input', { bubbles: true });

                    let house = body.house ? `, кв: ${body.house}` : '';
                    let approach = body.approach ? `, подъезд: ${body.house}` : '';
                    let floor = body.level ? `, этаж: ${body.level}` : '';
                    let intercom = body.intercom ? `, домофон: ${body.intercom}` : '';
                    let newValue = `${body.address}${house}${approach}${floor}${intercom}`;

                    addressSelect.innerText = newValue;
                    inputAddress.value = newValue;

                    inputAddress.dispatchEvent(event);
                    hideGlobalMenuListener()
                }
            }
        );
    }

    const addressForms = document.querySelectorAll('.__address_form_change');
    if(addressForms.length > 0) {
        addressForms.forEach(form=>{
            const id = form.dataset.id;
            const formSelector = document.querySelector(`#${form.id}`);
            formValidate(
                formSelector,
                schemaAddressChange,
                (body) => {
                    sendPatch(body, `/api/profile/${id}/update-address-data/`)
                    .then(res => {
                        console.log(res);
                    });
                }
            );
        });
    }

    const mailingForm = document.querySelector('#mailingForm');
    if(mailingForm) {
        formValidate(
            mailingForm,
            schemaMailing,
            (body) => {
                sendData(body, '/api/mailing/subscribe/')
                .then(res => {
                    console.log(res)
                });
            }
        );
    }

    const faqForm = document.querySelector('#faq-callback');
    if(faqForm) {
        formValidate(
            faqForm,
            schemaFAQFeedback,
            (body) => {
                sendData(body, '/api/feedback/faq/')
                .then(res => {
                    successDialog('callback');
                    hideGlobalMenuListener()
                });
            }
        );
    }
}

function formValidate(form, schema, callBack, formData) {
    form.onsubmit = (e) => {
        e.preventDefault();
    }
    const button = form.elements.button;

    const inputs = Object.keys(getBody(form))
        .map(el=>
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el=>Array.from(el).find(elem=>elem.closest(`#${form.id}`)));

    inputs.forEach((el)=>{
        el.oninput = (evt) => {
            inputs.forEach((e) => {
                e.classList.remove('error')
                if(e.parentNode.classList.contains('field')) {
                    e.parentNode.classList.remove('error');
                } else {
                    const field = findParentWithClass(e, 'field')
                    field.classList.remove('error')
                }
            });

            const body = getBody(evt.target.form);
            validateParse({
                schema: schema,
                body,
            }).then(res => {
                if(button.disabled) button.disabled = false;
                button.onclick = () => {
                    const formDataBody = new FormData(form);
                    if(callBack) formData ? callBack(formDataBody) : callBack(body);
                }
            }).catch(error => {
                const parse = JSON.parse(`[${getTextInsideSquareBrackets(error.toString())}]`);
                const nameErrorInput = parse.map(el=>el.path[0]);
                const input = inputs.filter(el=>nameErrorInput.includes(el.name));
                if(button && !button.disabled) button.disabled = true;
                input.forEach((el) => {
                    el.classList.add('error');
                });
                button.onclick = () => {};
            });
        }
    })
}


async function validateParse(validateInfo) {
    try {
        validateInfo.schema.parse(validateInfo.body);
        console.log("Validation successful");
        if(typeof validateInfo?.callback == 'function')validateInfo?.callback();
        return true;
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            // console.error("Validation failed:", error.errors);
            throw new Error(JSON.stringify(error.errors));
        } else {
            // console.error("Unknown error", error);
        }
    }
}

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
}

function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
}

async function setCookieAuthUser() {
    const wishlist = getWishlistFromCookie();
    const basket = getBasketFromCookie();
    if(wishlist.length > 0) {
        for(const elem of wishlist) {
            await removeFromWishlist(elem.product);
            await sendData(elem, '/api/wishlist/add/');
        };
    };
    if(basket.length > 0) {
        for(const elem of basket) {
            await removeFromBasket(elem.product);
            await sendData(elem, '/api/basket/add/');
        };
    };
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { validation } from "./путь/к/файлу/validation.js";

// Активация: validation();