import { userLoggedIn } from "../cookies.js";

export function addressChange() {
    const forms = document.querySelectorAll('.__address_form_change');
    const form = document.querySelector('#create-address-form');
    if(forms.length > 0) {
        forms.forEach(form=>{
            showNhideInputs(form);
        });
    };
    if(form) {
        showNhideInputs(form);
    }
    const deleteAddressBtns = document.querySelectorAll('.__delete_address__btn');
    if(deleteAddressBtns.length > 0) {
        deleteAddressBtns.forEach(btn=>{
            const id = btn.dataset.id;
            btn.addEventListener('click',()=>{
                deleteData(`/api/profile/${id}/delete-address/`)
                .then(res=>{
                    window.location.reload();
                });
            });
        });
    }
}

function showNhideInputs(form) {
    const checkbox = form.querySelector('input[type="checkbox"]');
    checkbox.addEventListener('click',()=>{
        const fields = form.querySelectorAll('.__field_hide');
        if(checkbox.checked) {
            fields.forEach(field=>{
                const input = field.querySelector('input');

                const event = new Event('input', { bubbles: true });
                input.dispatchEvent(event);

                input.value = '';
                field.style.display = 'none';
            });
        } else { 
            fields.forEach(field=>{
                field.style.display = null;
            });
        }
    });
}

async function deleteData(url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                'X-CSRFToken': csrfToken,
                'Authorization': 'Token ' + userLoggedIn(),
            },
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};