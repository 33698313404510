export function successDialog(dataDialog) {
    const dialog = document.querySelectorAll('.success-dialog');
    dialog.forEach(menu=>{
        const dataAttr = menu.dataset.dialog;
        if(dataAttr == dataDialog) animateDialog(menu);
    });
}
function animateDialog(dialog) {
    const runner = dialog.querySelector('.success-dialog__runner')
    runner.classList.add('animated');
    dialog.classList.add('active');
    setTimeout(() => {
        dialog.classList.remove('active');
    }, 4400);
    setTimeout(() => {
        runner.classList.remove('animated');
    }, 5000);
}