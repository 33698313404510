import { btnBasketFunc } from "./basketFunctions.js";

export function addInBasket() {
    const addInBasketBtns = document.querySelectorAll('.__add_in_basket');
    if(addInBasketBtns.length > 0) {
        addInBasketBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                btnBasketFunc(btn);
            });
        });
    };
}