import { userLoggedIn } from "../../cookies.js";
import { sendData } from "../../utils.js";
import { counterFunc } from "./cardsFunctions.js";

export function dinamicPrices() {
    const currentPage = window.location.pathname;
    const container = document.querySelector('.basket-section__items-list');
    if(currentPage.includes('/basket') && container && !userLoggedIn()) {
        const cards = container.querySelectorAll('.basket-card');
        cards.forEach(card=>{
            const counter = card.querySelector('.counter');
            counterFunc(counter, card.dataset.id);
        });
    };
}

// Динамическое калькулирование цены внутри корзины

export function calcPrice() {
    const container = document.querySelector('.basket-section__items-list');
    const menu = document.querySelector('.basket-section__menu-block');
    if(container && menu) {
        const cards = container.querySelectorAll('.basket-card');

        const calcTotalBlocks = menu.querySelectorAll('.__calculate-total');

        let allCheckedProducts = [];

        cards.forEach(card=>{
            const selector = card.querySelector('.__card_selector');
            const counterValue = card.querySelector('.counter').querySelector('input').value;
            if(selector.checked) {
                const productObj = {};
                
                productObj.product = parseInt(card.dataset.id);
                productObj.quantity = parseInt(counterValue);
                allCheckedProducts.push(productObj);
            };
        });

        console.log(allCheckedProducts);


        sendData(allCheckedProducts, '/api/basket/calculate-price/')
        .then(data=>{
            calcTotalBlocks.forEach(block=>{
                const calcAttr = block.dataset.calc;
                for (let dataCalc in data) {
                    if(dataCalc == calcAttr) block.innerText = data[dataCalc];
                }
            });
        });
    }
};