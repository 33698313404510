import { userLoggedIn } from "./cookies.js";

export function debounce(func, timeout) {
    let timer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            func.apply(context, args);
        }, timeout);
    };
};
export function findParentWithClass(element, className) {
    let parent = element.parentElement;

    while (parent) {
        if (parent.classList.contains(className)) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return null;
}
export async function sendData(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Token ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    } else {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

export async function getData(url) {
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': 'Token ' + userLoggedIn(),
            },
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    } else {
        const response = await fetch(url, {
            method: "GET",
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

export function setNewQuery(key, value) {
    const url = new URLSearchParams(window.location.search);
    url.delete(key);
    if(Array.isArray(value)) {
        url.append(key, value);
    } else {
        if(!!value) url.append(key, value);
    }

    window.location.search = url.toString();
};
export function getCurrentQuery() {
    const queryParams = new URLSearchParams(window.location.search);
    const filtersArray = [];
    for (let pair of queryParams.entries()) {
        const keysValue = {};
        let key = pair[0];
        let value = pair[1];
        keysValue[`${key}`] = value;
        filtersArray.push(keysValue);
    };
    return filtersArray;
}

export async function sendPatch(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Token ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    } else {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};