import { userLoggedIn } from "../../cookies.js";
import { getData } from "../../utils.js";
import { getWishlistFromCookie, removeFromWishlist } from "../../wishlist/wishlistFunctions.js";

export function deleteAllWishlist() {
    const currentUrl = window.location.pathname;
    if(currentUrl.includes('/wishlist/')) {
        const deleteAllBtn = document.querySelector('#__delete_all_wishist');
        if(deleteAllBtn) {
            deleteAllBtn.addEventListener('click',()=>{
                if(userLoggedIn()) {
                    getData('/api/wishlist/flush');
                } else {
                    const wishlist = getWishlistFromCookie();
                    wishlist.forEach(elem=>{
                        removeFromWishlist(elem.product);
                    });
                };
    
                setTimeout(() => {
                    window.location.reload();
                }, 250);
            });
        };
    };
};