export function cityHelper() {
    const input = document.querySelector('#addressMapInput');
    let typingTimer; // таймер для отслеживания ввода
    const typingDelay = 500; // задержка перед отправкой запроса (500 мс)

    if (input) {
        input.addEventListener('input', (e) => {
            const addressMapInput = e.target;
            // Очищаем предыдущий таймер
            clearTimeout(typingTimer);

            // Устанавливаем новый таймер
            typingTimer = setTimeout(() => {
                getSuggestInfo(addressMapInput.value)
                    .then(data => {
                        const cityHelper = document.querySelector('#cityHelper');
                        const addresses = parseSuggestAddress(data);

                        if (addresses) {
                            while (cityHelper.firstChild) {
                                cityHelper.removeChild(cityHelper.firstChild);
                            }

                            addresses.forEach((address) => {
                                let cityHelperItem = document.createElement('div');
                                cityHelperItem.classList.add('city-helper-item');

                                cityHelperItem.textContent = address;
                                cityHelper.appendChild(cityHelperItem);
                                cityHelperItem.addEventListener('click', setSelectAddress);
                            })

                            cityHelper.classList.add('show');
                        } else {
                            while (cityHelper.firstChild) {
                                cityHelper.removeChild(cityHelper.firstChild);
                            }
                            cityHelper.classList.remove('show');
                        }
                    });
            }, typingDelay);
        });
    }
}

export function parseSuggestAddress(data) {
    const results = data['results']
    const addresses = [];
    if (!results) {
        return null;
    }

    results.forEach((result) => {
        let address = '';
        if (result['subtitle']) {
            const city = result['subtitle'].text.split(',')[0];
            address = city + ', ' + result['title'].text;
        } else if (result['title']) {
            address = result['title'].text;
        }
        addresses.push(address);
    });
    return addresses;
}

export async function getSuggestInfo(address) {
    return await sendText('https://suggest-maps.yandex.ru/v1/suggest?apikey=6113898e-6805-4af0-a178-94df8a308a44\n', address);
}

function setSelectAddress(event) {
    const input = document.querySelector('#addressMapInput').querySelector('input');
    const changeEvent = new Event('change');
    input.dispatchEvent(changeEvent);
    input.value = event.target.innerText;
    document.querySelector('#cityHelper').classList.remove('show');
}

async function sendText(url, text) {
    const response = await fetch(url + '&text=' + text, {
        method: "GET",
    });
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
};