import { getBasketFromCookie } from "../../basket/basketFunctions.js";
import { userLoggedIn } from "../../cookies.js";
import { getData } from "../../utils.js";
import { addCard } from "./addCard.js";

export async function showCards() {
    const basket = getBasketFromCookie()
    const container = document.querySelector('.basket-section__items-list');
    if(basket && container && basket.length > 0 && !userLoggedIn()) {
        basket.forEach(elem=>{
            const id = parseInt(elem.product);
            getData(`/api/products/${id}/`)
            .then(data=>{
                addCard(data, container, elem.quantity);
            });
        });
    } else if (container && userLoggedIn()) {
        const cards = container.querySelectorAll('.basket-card');
        if(cards.length > 0) {
            cards.forEach(card=>{
                const checkbox = card.querySelector('.__card_selector');
                checkbox.checked = true;
            });
        };
    };
}