export function productCard() {
    let productCards = document.querySelectorAll('.product-card');

    if(productCards) {
        productCards.forEach(card => {
            let productImg = card.querySelectorAll('.product-card__img-block img');
            let productTab = card.querySelectorAll('.product-card__tabs__tab');
    
            productTab.forEach(tab => {
                tab.addEventListener('mouseenter', function() {
                    productImg.forEach(img => {
                        if(tab.getAttribute('data-image-tab') === img.getAttribute('data-image')) {
                            img.style.opacity = '1';
                        } else {
                            img.style.opacity = '0';
                        }
                    });
                });
            });
        });
    }
}