import { getBasketFromCookie } from "../../basket/basketFunctions.js";
import { userLoggedIn } from "../../cookies.js";

export function checkForEmptyUnreg() {
    const currentPage = window.location.pathname;
    const basket = getBasketFromCookie();

    const basketSection = document.querySelector('#basket');
    const basketEmptyBlock = document.querySelector('#basket-empty');
    const notAuthSection = document.querySelector('#not-auth');

    if(currentPage.includes('/basket/') && basket && basket.length < 1 && !userLoggedIn()) {
        basketSection.parentNode.removeChild(basketSection);
        notAuthSection.parentNode.removeChild(notAuthSection);
        basketEmptyBlock.style.display = 'block';
    } else if (currentPage.includes('/basket/') && !userLoggedIn()) {
        notAuthSection.style.display = 'block';
    }
}