import { setCookie, userLoggedIn } from '../cookies.js';
import { updateBadgeCount } from '../count-header.js';
import { moveToOrder } from '../order/moveToOrder.js';
import { debounce, sendData } from '../utils.js';

export const getCookiesBasket = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)basket\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export function getBasketFromCookie() {
    const basketCookies = getCookiesBasket();
    return basketCookies ? JSON.parse(basketCookies) : [];
}

export async function addToBasket(productId, quantity) {
    if(userLoggedIn()) {
        const productObj = {};
        if (typeof productId === 'string' || productId instanceof String) {
            productObj.product = parseInt(productId);
        } else if (quantity > 0) {
            productObj.product = productId;
        }
        if (typeof quantity === 'string' || quantity instanceof String) {
            productObj.quantity = parseInt(quantity);
        } else {
            productObj.quantity = quantity;
        }
        sendData(productObj, '/api/basket/add/');
    } else {
        let basket = getBasketFromCookie();
        const index = basket.findIndex(item => parseInt(item.product) === parseInt(productId)) 
        if (index !== -1) {
            basket.splice(index, 1);
        }
        if(quantity > 0 && typeof productId === 'string' || productId instanceof String) {
            basket.push({
                "product": parseInt(productId),
                "quantity": quantity,
            });
        } else if (quantity > 0) {
            basket.push({
                "product": productId,
                "quantity": quantity,
            });
        }
        setCookie('basket', JSON.stringify(basket));
    }
    updateBadgeCount()
}

export async function removeFromBasket(productId) {
    if(userLoggedIn()) {
        const productObj = {};
        if (typeof productId === 'string' || productId instanceof String) {
            productObj.product = parseInt(productId);
        } else {
            productObj.product = productId;
        }
        // if (typeof quantity === 'string' || quantity instanceof String) {
        //     productObj.quantity = parseInt(quantity);
        // } else {
        //     productObj.quantity = quantity;
        // }
        sendData(productObj, '/api/basket/remove/');
    } else {
        let basket = getBasketFromCookie();
        let index = '';
        if(typeof productId === 'string' || productId instanceof String) {
            index = basket.findIndex(item => item.product === parseInt(productId)) 
        } else {
            index = basket.findIndex(item => item.product === productId) 
        }
        if (index !== -1) {
            basket.splice(index, 1);
        }
        setCookie('basket', JSON.stringify(basket));
    }
    updateBadgeCount()
};

export function replaceButtonWithCounter(button, quantity) {
    const newBtn = button.cloneNode(true);
    button.replaceWith(newBtn);
    const id = button.dataset.id;

    const value = quantity ? quantity : 1;

    newBtn.className = 'counter';
    newBtn.innerHTML = `
    <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="minus">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 8.25H14.25V9.75H3.75V8.25Z" fill="#1D1D1C"/>
        </svg>
    </button>
    <input type="text" value="${value}"></input>
    <button class="icon-button icon-button_size_small icon-button_primary __counter_btn" data-char="plus">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" fill="#1D1D1C"/>
        </svg>
    </button>
    `
    const countBtns = newBtn.querySelectorAll('.__counter_btn');
    const input = newBtn.querySelector('input');
    countBtns.forEach(countBtn=>{
        const char = countBtn.dataset.char;
        countBtn.addEventListener('click',()=>{
            if(char == 'minus' && parseInt(input.value) > 0) {
                input.value = parseInt(input.value) - 1;
            } else if(char == 'plus') {
                input.value = parseInt(input.value) + 1;
            };

            addToBasket(id, parseInt(input.value))
        });
        input.addEventListener('input',()=>{
            debouncedAddToBasket(id, parseInt(input.value));
        });
    });
}

export function btnBasketFunc(btn) {
    const id = btn.dataset.id;
    addToBasket(id, 1);
    
    replaceButtonWithCounter(btn);
}
const debouncedAddToBasket = debounce(addToBasket, 300);

moveToOrder()