import { debounce, getCurrentQuery, getData } from "../../utils.js";
import { addAccordion } from "./addAccordion.js";

export async function searcher() {
    const searchInput = document.querySelector('#support-search-input');
    if(searchInput) {
        searchInput.addEventListener('input',()=>{
            const value = searchInput.value;
            debouncedSearch(value);
        });
    };
    const closeSearcherBtn = document.querySelector('.__close_searcher');
    if(closeSearcherBtn) {
        closeSearcherBtn.addEventListener('click',()=>{
            hideSearchSection();
            searchInput.value = '';
        });
    };
}

const debouncedSearch = debounce(searchFunctionary, 500);

function searchFunctionary(value) {
    getData(`/api/faq/?q=${value}`)
    .then(res=>{
        showSearchSection();
        const results = res.results;
        if(results.length > 0 && value !== '') {
            showInfoBlock(results);
        } else {
            hideInfoBlock();
        };
    });
}

function hideSearchSection() {
    const searchSection = document.querySelector('.support-search__section');
    const hide = document.querySelectorAll('.__hide_for_search');
    const closeSearcherBtn = document.querySelector('.__close_searcher');

    searchSection.style.display = null;
    hide.forEach(elem=>{
        elem.style.display = null;
    });
    closeSearcherBtn.style.display = null;
}
function showSearchSection() {
    const searchSection = document.querySelector('.support-search__section');
    const hide = document.querySelectorAll('.__hide_for_search');
    const closeSearcherBtn = document.querySelector('.__close_searcher');

    searchSection.style.display = 'flex';
    hide.forEach(elem=>{
        elem.style.display = 'none';
    });
    closeSearcherBtn.style.display = 'flex';
}
async function showInfoBlock(res) {
    const infoBlock = document.querySelector('.support-search__info-block');
    const notFound = document.querySelector('.support-search__not-found');

    infoBlock.style.display = 'flex';
    notFound.style.display = 'none';

    const fragment = document.createDocumentFragment();
    for (const data of res) {
        addAccordion(data, fragment);
    }
    infoBlock.innerHTML = '';
    infoBlock.appendChild(fragment);
}
function hideInfoBlock() {
    const infoBlock = document.querySelector('.support-search__info-block');
    const notFound = document.querySelector('.support-search__not-found');

    infoBlock.style.display = 'none';
    notFound.style.display = 'flex';
}